import user from "../../assets/media/avatars/blank.png";
import { BurgerIcon } from "../Icons";
import logo from "../../assets/logos/logo.svg";
import i18n from "./../../localization/i18next";
import { setStorage } from "../../helpers/localStorage";
import { Avatar, Badge, Select } from "antd";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import React, { useEffect, useState } from "react";
import { logoutUser } from "../../redux/services/authService";
import ConfirmModal from "../core/ConfirmModal";
import instance from "../../axios";
import { useTranslation } from "react-i18next";
import { UserControllerService } from "../../services/openapi";
import { toast } from "react-toastify";

type Props = {
  handleOpenAside?: (e: React.MouseEvent<HTMLElement>) => void;
};
const Header: React.FC<Props> = ({ handleOpenAside }) => {
  const { t } = useTranslation();
  const Option = Select.Option;
  const { userMe } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [show, setShow] = useState<boolean>(false);
  const ref = React.createRef<HTMLInputElement>();

  const lang = i18n.language || userMe.lang;

  const langChange = async (value: string) => {
    await i18n.changeLanguage(value);
    setStorage("_lang", value);
    try {
      const res = await UserControllerService.changeLanguageUsingPost(
        value as any
      );
      if (res.success) {
        toast(res.message, {
          type: "success",
        });
      }
    } catch (err: any) {
      toast(err.body.errors[0].errorMsg, {
        type: "error",
      });
    }
  };

  const logOut = () => {
    dispatch(logoutUser());
  };
  const toggleShow = () => {
    setShow((show) => !show);
  };

  useEffect(() => {
    const clickOutside = (e: any) => {
      if (show && ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, [show, ref]);

  const UserInfo = () => {
    return (
      <div
        className={`menu menu-sub menu-sub-dropdown 
                menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary
                fw-bold py-4 fs-6 min-w-275px w-325px mw-375px position-absolute top-100 end-0 ${
                  show && "show"
                }`}
      >
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-3">
            <div className="symbol symbol-50px me-5 overflow-hidden">
              <img
                className="avatar-img"
                alt={
                  userMe?.firstName &&
                  userMe?.lastName &&
                  userMe?.firstName[0] + userMe?.lastName[0]
                }
                src={
                  userMe?.avatarUrl !== undefined && userMe?.avatarUrl !== null
                    ? `${instance.defaults.baseURL}/api/v1/file${userMe?.avatarUrl}`
                    : user
                }
              />
            </div>
            <div className="d-flex flex-column">
              <div className="fw-bolder d-flex align-items-center fs-5 text-nowrap">
                {userMe?.firstName} {userMe?.lastName && userMe?.lastName[0]}
              </div>
              <a
                href="/#"
                className="fw-bold text-muted text-hover-primary fs-7"
              >
                {userMe?.organization?.email}
              </a>
            </div>
          </div>
        </div>
        <div className="separator my-2"></div>
        <div className="menu-item px-5 my-1">
          <Link to="/profile" className="menu-link px-5">
            {t("profileSettings")}
          </Link>
        </div>
        <div className="menu-item px-5">
          <ConfirmModal
            userDropdown={true}
            btnClass="btn-custom btn-primary w-100"
            btnTitle={t("logout")}
            title={t("confirmLogout")}
            handleSubmit={logOut}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="header align-items-stretch">
      <div className="container-fluid d-flex align-items-stretch justify-content-between">
        <div
          className="d-flex align-items-center d-lg-none ms-n3 me-1"
          title="Show aside menu"
        >
          <div
            className="btn btn-icon btn-active-light-primary"
            id="kt_aside_mobile_toggle"
            onClick={handleOpenAside}
          >
            <span className="svg-icon svg-icon-2x mt-1">
              <BurgerIcon />
            </span>
          </div>
        </div>

        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          <Link to="/" className="d-lg-none">
            <img alt="Logo" src={logo} className="h-30px" />
          </Link>
        </div>

        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          <div className="d-flex align-items-stretch flex-shrink-0 ms-auto">
            <div className="d-flex align-items-stretch flex-shrink-0">
              <div className="d-flex align-items-stretch ms-1 ms-lg-3">
                <div className="d-flex align-items-stretch">
                  <div className="d-flex align-items-center me-2">
                    <Select
                      defaultValue={lang}
                      style={{ width: 70 }}
                      onChange={langChange}
                    >
                      <Option value="RU">РУ</Option>
                      <Option value="EN">EN</Option>
                      <Option value="UZ">O'Z</Option>
                      {/*<Option value="UZK">ЎЗ</Option>*/}
                    </Select>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-stretch ms-1 ms-lg-3">
                <div className="d-flex align-items-stretch cursor-pointer symbol symbol-30px symbol-md-40px show menu-dropdown">
                  <div className="d-flex align-items-center" ref={ref}>
                    <div onClick={toggleShow} className="position-relative">
                      <Badge dot={true} status="success" offset={[-40, 40]}>
                        <Avatar shape="square" size="large" className="avatar">
                          <img
                            className="avatar-img"
                            alt={
                              userMe?.firstName &&
                              userMe?.lastName &&
                              userMe?.firstName[0] + userMe?.lastName[0]
                            }
                            src={
                              userMe?.avatarUrl !== undefined &&
                              userMe?.avatarUrl !== null
                                ? `${instance.defaults.baseURL}/api/v1/file${userMe?.avatarUrl}`
                                : user
                            }
                          />
                        </Avatar>
                      </Badge>
                    </div>
                    <UserInfo />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
