import {AppThunk} from "../store";
import {logout, setAccessToken, setIsLoading} from "../slices/auth";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import { setCookie} from "../../helpers/cookie";
import {
    ApiResultOfboolean,
    ApiResultOfSignInResponseDTO,
    AuthControllerService,
    OpenAPI,
    SignInDTO,
    UserControllerService,
} from "../../services/openapi";
import instance from "../../axios";


export const login =
    (data: SignInDTO): AppThunk =>
        async (dispatch): Promise<ApiResultOfSignInResponseDTO> => {
            dispatch(setIsLoading(true));
            try {
                const response = await AuthControllerService.signInUsingPost(data);
                if (
                    response.data?.token?.accessToken &&
                    response.data.token.refreshToken
                ) {
                  instance.defaults.headers.common = { Authorization: `Bearer ${response.data.token.accessToken}` };
                    dispatch(setAccessToken(response.data.token.accessToken));
                    setCookie("_token", response.data.token.accessToken);
                    OpenAPI.TOKEN = response.data.token.accessToken;
                    setCookie("_refreshToken", response.data.token.refreshToken);
                }
                return response;
            } catch (error: any) {
              delete instance.defaults.headers.common['Authorization'];
                toast(error.body.errors[0].errorMsg, {
                    type: "error",
                });
                return error;
            } finally {
                dispatch(setIsLoading(false));
            }
        };

export const logoutUser =
    (): AppThunk =>
        async (dispatch): Promise<ApiResultOfboolean | AxiosError> => {
  dispatch(logout());
            try {
                const response = await UserControllerService.logOutUsingGet();
                dispatch(logout());
                return response;
            } catch (error) {
                dispatch(logout());
                let err = error as AxiosError;
                toast(err.message, {
                    type: "error",
                });
                return err;
            } finally {
                dispatch(logout());
                dispatch(setIsLoading(false));
            }
        };
