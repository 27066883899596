import {EgovErrorResponseDTO, EgovRequestDTO} from "../../services/openapi";
import {createSlice} from "@reduxjs/toolkit";
import {getAllEgovErrors, getAllEgovRequests} from "../services/settingsService";

interface PermissionsState {
    egovErrors: EgovErrorResponseDTO[];
    egovRequests: EgovRequestDTO[];
    loading: boolean;
    status: string;
    error: string;
    totalCount: any
}

const initialState: PermissionsState = {
    egovErrors: [],
    egovRequests: [],
    loading: false,
    status: "",
    error: "",
    totalCount: 0
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {},
    extraReducers: {
        [getAllEgovErrors.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [getAllEgovErrors.fulfilled.type]: (state, {payload}) => {
            state.status = "success";
            state.egovErrors = payload.content;
            state.totalCount = payload.totalElements;
            state.loading = false;
        },
        [getAllEgovErrors.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [getAllEgovRequests.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [getAllEgovRequests.fulfilled.type]: (state, {payload}) => {
            state.status = "success";
            state.egovRequests = payload.content;
            state.totalCount = payload.totalElements;
            state.loading = false;
        },
        [getAllEgovRequests.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
    },
});

export default settingsSlice.reducer;
