import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  UserControllerService,
  UserCreateUpdateDTO,
} from "../../services/openapi";
import { toast } from "react-toastify";
import { IFilterUser } from "../../models";

export const getAllUsers = createAsyncThunk(
  "user/get-all",
  async (args: IFilterUser) => {
    try {
      const response = await UserControllerService.getAllUsingGet6(
        args.email,
        args.fio,
        args.id,
        args.organizationId,
        (args.page = args.page || 0),
        args.phone,
        args.roleId,
        args.shortName,
        (args.size = args.size || 25),
        args.status
      );
      return response.data;
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const createUser = createAsyncThunk(
  "user/create",
  async (data: UserCreateUpdateDTO) => {
    try {
      return await UserControllerService.createUsingPost4(data);
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const getOneUser = createAsyncThunk(
  "user/get-one",
  async (id: number) => {
    try {
      const response = await UserControllerService.getUsingGet5(id);
      return response.data;
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const getUserMe = createAsyncThunk("user/me", async () => {
  try {
    const response = await UserControllerService.getUserMeUsingGet();
    return response.data;
  } catch (error: any) {
    toast(error?.body?.errors[0]?.errorMsg || error.message, {
      type: "error",
    });
  }
});

export const getUserActive = createAsyncThunk(
  "user/active",
  async (args: { userId: number; active: boolean }) => {
    try {
      return await UserControllerService.activeUsingPost1(
        args.active,
        args.userId
      );
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (data: UserCreateUpdateDTO) => {
    try {
      return await UserControllerService.updateUsingPut4(data);
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/delete",
  async (id: number) => {
    try {
      return await UserControllerService.deleteUsingDelete5(id);
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const checkAttempt = createAsyncThunk(
    'user/check-attempt',
    async () => {
        try {
            const response  = await UserControllerService.getAttemptInfoUsingGet()
            return response.data;
        } catch (error: any){
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
)

// export const changeUser = createAsyncThunk(
//     "user/change",
//     async (data: IUsrInfo) => {
//         try {
//             return response = await UserControllerService.changeInfoUsingPost(
//                 data.avatarId,
//                 data.email,
//                 data.newPassword,
//                 data.oldPassword,
//                 data.repeatNewPassword);
//         } catch (error: any) {
//             toast(error?.body?.errors[0]?.errorMsg || error.message, {
//                 type: "error",
//             });
//         }
//     }
// );
