import React from 'react';
import {DownloadReport} from "../../components";

const ReportFile = () => {
    return (
        <DownloadReport/>
    );
};

export default ReportFile;
