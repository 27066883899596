import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import Toolbar from "../../Toolbar";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getDubiousOrganizations } from "../../../redux/services/supplierService";
import { ColumnGroupType, ColumnType } from "antd/lib/table";
import { ObnalSupplierDto } from "../../../services/openapi";
import { PaginationProps } from "antd";
import CardBody from "../../core/CardBody";
import CustomTable from "../../core/CustomTable";
import Input from "../../core/Input";
import { IFilterDubiousOrganizations } from "../../../models";
import { SVG } from "../../core/SVG";
import { DeleteIcon, SearchTableIcon } from "../../Icons";
import { fieldCheck } from "../../../helpers/fieldCheck";

const DubiousOrganizations = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(25);
  const [filter, setFilter] = useState<IFilterDubiousOrganizations>({
    city: "",
    inn: "",
    organizationName: "",
    registerDate: "",
    founderName: "",
  });

  const onChangePagination: PaginationProps["onChange"] = (pageNumber) => {
    setPage(pageNumber - 1);
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPage(current);
    setSize(pageSize);
  };
  const dispatch = useAppDispatch();
  const { dubiousOrganizations, dubiousTotalCount, dbLoader, error } =
    useAppSelector((state) => state.supplier);

  useEffect(() => {
    dispatch(getDubiousOrganizations({ page, size }));
  }, [dispatch, page, size]);

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    setFilter((filter) => ({ ...filter, [name]: value }));
  };

  const handleFilter = async () => {
    await dispatch(
      getDubiousOrganizations({
        page: 0,
        size,
        ...fieldCheck(filter),
      })
    );
  };
  const handleClearFilter = async () => {
    setFilter({
      city: "",
      inn: "",
      organizationName: "",
      registerDate: "",
      founderName: "",
    });
    await dispatch(
      getDubiousOrganizations({
        page: 0,
        size,
      })
    );
  };

  const columns: (
    | ColumnGroupType<ObnalSupplierDto>
    | ColumnType<ObnalSupplierDto>
  )[] = [
    {
      title: "№",
      key: "id",
      width: "80px",
      className: "text-center gx-0",
      render: (row) => {
        let index = dubiousOrganizations.indexOf(row);
        return page * size + index + 1;
      },
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("region")}</div>
          <div className="fv-row mb-4 w-150px min-w-100 d-flex">
            <Input
              type="text"
              name="city"
              value={filter.city}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "city",
      key: "city",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("tin")}</div>
          <div className="fv-row mb-4 w-150px min-w-100 d-flex">
            <Input
              type="text"
              name="inn"
              value={filter.inn}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "inn",
      key: "inn",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("organization")} </div>
          <div className="fv-row mb-4 w-150px min-w-100">
            <Input
              type="text"
              name="organizationName"
              value={filter.organizationName}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "organizationName",
      key: "organizationName",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("registerDate")} </div>
          <div className="fv-row mb-4 w-150px min-w-100">
            <Input
              type="text"
              name="registerDate"
              value={filter.registerDate}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "registerDate",
      key: "registerDate",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("founderName")} </div>
          <div className="fv-row mb-4 w-150px min-w-100 d-flex">
            <Input
              type="text"
              name="founderName"
              value={filter.founderName}
              onChange={handleChangeFilter}
            />
            <div className="fv-row mb-4">
              <div
                onClick={handleClearFilter}
                className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0"
              >
                <SVG icon={<DeleteIcon />} className="svg-icon-1" />
              </div>
            </div>
            <div className="fv-row mb-4">
              <div
                onClick={handleFilter}
                className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0"
              >
                <SVG icon={<SearchTableIcon />} className="svg-icon-1" />
              </div>
            </div>
          </div>
        </div>
      ),
      dataIndex: "founderName",
      key: "founderName",
    },
  ];

  const data = useMemo(() => dubiousOrganizations, [dubiousOrganizations]);

  return (
    <>
      <Toolbar title={t("dubiousOrganizations")} />
      <CardBody
        children={
          <CustomTable
            columns={columns}
            error={error}
            data={data}
            loading={dbLoader}
            page={page}
            pageSize={size}
            className="table-striped-rows"
            paginationShowChange={onShowSizeChange}
            paginationChange={onChangePagination}
            totalCount={dubiousTotalCount}
          />
        }
      />
    </>
  );
};

export default DubiousOrganizations;
