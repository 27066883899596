import {createAsyncThunk} from "@reduxjs/toolkit";
import {IFilterOrganization} from "../../models";
import {OrganizationControllerService, OrganizationCreateUpdateDTO} from "../../services/openapi";
import {toast} from "react-toastify";

export const getAllOrganizations = createAsyncThunk(
    "organization/get-all",
    async (args: IFilterOrganization) => {
      try {
        const response = await OrganizationControllerService.getAllUsingGet2(
            args.address,args.email, args.id, args.inn,
            args.name, (args.page = args.page || 0),
            args.phone,
            (args.size = args.size || 25),

        );
        return response.data;
      } catch (error: any) {
        toast(error?.body?.errors[0]?.errorMsg || error.message, {
          type: "error",
        });
      }
    }
);

export const createOrganization = createAsyncThunk(
    "organization/create",
    async (data: OrganizationCreateUpdateDTO) => {
      try {
        return await OrganizationControllerService.createUsingPost1(
            data
        );
      } catch (error: any) {
        toast(error?.body?.errors[0]?.errorMsg || error.message, {
          type: "error",
        });
      }
    }
);

export const getOneOrganization = createAsyncThunk(
    "organization/get-one",
    async (id: number) => {
      try {
        const response = await OrganizationControllerService.getUsingGet2(id);
        return response.data;
      } catch (error: any) {
        toast(error?.body?.errors[0]?.errorMsg || error.message, {
          type: "error",
        });
      }
    }
);

export const updateOrganization = createAsyncThunk(
    "organization/update",
    async (data: OrganizationCreateUpdateDTO) => {
      try {
        return await OrganizationControllerService.updateUsingPut1(
            data
        );
      } catch (error: any) {
        toast(error?.body?.errors[0]?.errorMsg || error.message, {
          type: "error",
        });
      }
    }
);

export const deleteOrganization = createAsyncThunk(
    "organization/delete",
    async (id: number) => {
      try {
        return await OrganizationControllerService.deleteUsingDelete1(
            id
        );
      } catch (error: any) {
        toast(error?.body?.errors[0]?.errorMsg || error.message, {
          type: "error",
        });
      }
    }
);

