/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResultOfboolean } from '../models/ApiResultOfboolean';
import type { ApiResultOfPageOfUserDTO } from '../models/ApiResultOfPageOfUserDTO';
import type { ApiResultOfstring } from '../models/ApiResultOfstring';
import type { ApiResultOfUserAttemptDTO } from '../models/ApiResultOfUserAttemptDTO';
import type { ApiResultOfUserDetailsDTO } from '../models/ApiResultOfUserDetailsDTO';
import type { ApiResultOfUserDTO } from '../models/ApiResultOfUserDTO';
import type { UserCreateUpdateDTO } from '../models/UserCreateUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserControllerService {

    /**
     * create
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static createUsingPost4(
dto: UserCreateUpdateDTO,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/',
            body: dto,
        });
    }

    /**
     * update
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static updateUsingPut4(
dto: UserCreateUpdateDTO,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/user/',
            body: dto,
        });
    }

    /**
     * active
     * @param active active
     * @param userId userId
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static activeUsingPost1(
active: boolean,
userId: number,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/active',
            query: {
                'active': active,
                'userId': userId,
            },
        });
    }

    /**
     * changeInfo
     * @param avatarId 
     * @param email 
     * @param newPassword 
     * @param oldPassword 
     * @param repeatNewPassword 
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static changeInfoUsingPost(
avatarId?: number,
email?: string,
newPassword?: string,
oldPassword?: string,
repeatNewPassword?: string,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/change-info',
            query: {
                'avatarId': avatarId,
                'email': email,
                'newPassword': newPassword,
                'oldPassword': oldPassword,
                'repeatNewPassword': repeatNewPassword,
            },
        });
    }

    /**
     * changeLanguage
     * @param lang lang
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static changeLanguageUsingPost(
lang: 'UZ' | 'RU' | 'EN',
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/change-lang',
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * changePassword
     * @param newPassword newPassword
     * @param oldPassword oldPassword
     * @param repeatNewPassword repeatNewPassword
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static changePasswordUsingPost(
newPassword: string,
oldPassword: string,
repeatNewPassword: string,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/change-password',
            query: {
                'newPassword': newPassword,
                'oldPassword': oldPassword,
                'repeatNewPassword': repeatNewPassword,
            },
        });
    }

    /**
     * User o'zining ma'lumotlarini olish
     * @returns ApiResultOfUserAttemptDTO OK
     * @throws ApiError
     */
    public static getAttemptInfoUsingGet(): CancelablePromise<ApiResultOfUserAttemptDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/check-attempt',
        });
    }

    /**
     * getAll
     * @param email 
     * @param fio 
     * @param id 
     * @param organizationId 
     * @param page page
     * @param phone 
     * @param roleId 
     * @param shortName 
     * @param size size
     * @param status 
     * @returns ApiResultOfPageOfUserDTO OK
     * @throws ApiError
     */
    public static getAllUsingGet6(
email?: string,
fio?: string,
id?: number,
organizationId?: number,
page?: number,
phone?: string,
roleId?: number,
shortName?: string,
size: number = 20,
status?: 'ACTIVE' | 'DELETED' | 'BLOCKED',
): CancelablePromise<ApiResultOfPageOfUserDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/get-all',
            query: {
                'email': email,
                'fio': fio,
                'id': id,
                'organizationId': organizationId,
                'page': page,
                'phone': phone,
                'roleId': roleId,
                'shortName': shortName,
                'size': size,
                'status': status,
            },
        });
    }

    /**
     * Tizimdagi (ya'ni security contextdagi) User tizimdan chiqish qilganda, uning tokenini ham ham qayta foydalanilmaydigan qilish uchun api
     * @returns ApiResultOfboolean OK
     * @throws ApiError
     */
    public static logOutUsingGet(): CancelablePromise<ApiResultOfboolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/logout',
        });
    }

    /**
     * Tizimdagi (ya'ni security contextdagi) User o'zining ma'lumotlarini olish
     * @returns ApiResultOfUserDetailsDTO OK
     * @throws ApiError
     */
    public static getUserMeUsingGet(): CancelablePromise<ApiResultOfUserDetailsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/me',
        });
    }

    /**
     * get
     * @param id id
     * @returns ApiResultOfUserDTO OK
     * @throws ApiError
     */
    public static getUsingGet5(
id: number,
): CancelablePromise<ApiResultOfUserDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * delete
     * @param id id
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static deleteUsingDelete5(
id: number,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/user/{id}',
            path: {
                'id': id,
            },
        });
    }

}
