import {UserAttemptDTO, UserCreateUpdateDTO, UserDetailsDTO, UserDTO} from "../../services/openapi";
import {getStorage, setStorage} from "../../helpers/localStorage";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    checkAttempt,
    createUser,
    getAllUsers,
    getOneUser,
    getUserActive,
    getUserMe,
    updateUser
} from "../services/user";

interface UserState {
    users: UserDTO[];
    user: UserCreateUpdateDTO;
    userMe: UserDetailsDTO;
    attempt: UserAttemptDTO;
    userStatus: any;
    loading: boolean;
    status: string;
    error: string;
    response: any;
    totalCount: any
}

const initialState: UserState = {
    users: [],
    user: {
        organizationId: undefined,
        roleId: undefined,
    },
    userMe: getStorage("user") ? JSON.parse(getStorage("user") || `{}`)  : {
        email: "",
        firstName: "",
        avatarUrl: "",
        lang: "",
        middleName: "",
        lastName: "",
        phoneNumber: "",
        id: undefined,
        organizationName: "",
        roleName: "",
        permissions: []
    },
    attempt: {
        attemptCount: undefined,
        expireDate: ''
    },
    userStatus: {},
    loading: false,
    status: "",
    error: "",
    response: {},
    totalCount: 0
};
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state:any, action: PayloadAction<{ name: any; value: string }>) => {
            state.user = {
                ...state.user,
                [action.payload.name]: action.payload.value,
            };
        },
        clearUser: (state: any) => {
            state.user = {
                avatarId: undefined,
                email: "",
                firstName: "",
                lastName: "",
                middleName: "",
                organizationId: undefined,
                password: "",
                phoneNumber: "",
                positionTypeId: undefined,
                roleId: undefined
            };
        },
    },
    extraReducers: {
        [getAllUsers.pending.type]: (state:any) => {
            state.status = "pending";
            state.loading = true;
        },
        [getAllUsers.fulfilled.type]: (state:any, { payload }:any) => {
            state.status = "success";
            state.users = payload.content;
            state.totalCount = payload.totalElements;
            state.loading = false;
        },
        [getAllUsers.rejected.type]: (state:any, action:any) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [createUser.pending.type]: (state:any) => {
            state.status = "pending";
            state.loading = true;
        },
        [createUser.fulfilled.type]: (state:any, { payload }:any) => {
            state.status = "success";
            state.response = payload;
            state.loading = false;
        },
        [createUser.rejected.type]: (state:any, action:any) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [getOneUser.pending.type]: (state:any) => {
            state.status = "pending";
            state.loading = true;
        },
        [getOneUser.fulfilled.type]: (state:any, { payload }:any) => {
            state.status = "success";
            state.user = payload;
            state.loading = false;
        },
        [getOneUser.rejected.type]: (state:any, action:any) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [getUserMe.pending.type]: (state:any) => {
            state.status = "pending";
            state.loading = true;
        },
        [getUserMe.fulfilled.type]: (state: any, { payload }: any) => {
            state.status = "success";
            state.userMe = payload;
            state.loading = false;
            setStorage('user', JSON.stringify(payload || {}))
        },
        [getUserMe.rejected.type]: (state: any, action: any) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [updateUser.pending.type]: (state:any) => {
            state.status = "pending";
            state.loading = true;
        },
        [updateUser.fulfilled.type]: (state: any, { payload }:any) => {
            state.status = "success";
            state.response = payload;
            state.loading = false;
        },
        [updateUser.rejected.type]: (state:any, action:any) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [getUserActive.pending.type]: (state:any) => {
            state.status = "pending";
            state.loading = true;
        },
        [getUserActive.fulfilled.type]: (state:any, { payload }:any) => {
            state.status = "success";
            state.userStatus = payload;
            state.loading = false;
        },
        [getUserActive.rejected.type]: (state:any, action:any) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [checkAttempt.pending.type]: (state:any) => {
            state.status = "pending";
            state.loading = true;
        },
        [checkAttempt.fulfilled.type]: (state:any, { payload }:any) => {
            state.status = "success";
            state.attempt = payload
            state.loading = false;
        },
        [checkAttempt.rejected.type]: (state:any, action:any) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
    },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
