import axios from "axios";
import { getCookie } from "../helpers/cookie";

const token = getCookie("_token");

const instance = axios.create({
  baseURL: "https://app.kontragent.uz"
});

if (token) {
  instance.defaults.headers.common = { Authorization: `Bearer ${token}` };
}
export default instance;

