/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResultOfCheckedSupplierDto } from '../models/ApiResultOfCheckedSupplierDto';
import type { ApiResultOfInfoDTO } from '../models/ApiResultOfInfoDTO';
import type { ApiResultOfPageOfCheckedSupplierDto } from '../models/ApiResultOfPageOfCheckedSupplierDto';
import type { ApiResultOfstring } from '../models/ApiResultOfstring';
import type { ApplicationCreatedDTO } from '../models/ApplicationCreatedDTO';
import type { ApplicationDescriptionUpdateDTO } from '../models/ApplicationDescriptionUpdateDTO';
import type { ApplicationUpdateDTO } from '../models/ApplicationUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CheckedSupplierControllerService {

    /**
     * appComplete
     * @param id id
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static appCompleteUsingPost(
id: number,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/checked-supplier/application/complete/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * sendApp
     * @param captcha captcha
     * @param captchaId captchaId
     * @param jSessionId jSessionId
     * @param tin tin
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static sendAppUsingGet(
captcha?: string,
captchaId?: string,
jSessionId?: string,
tin?: string,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/checked-supplier/application/create',
            query: {
                'captcha': captcha,
                'captchaId': captchaId,
                'jSessionId': jSessionId,
                'tin': tin,
            },
        });
    }

    /**
     * appCreate
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static appCreateUsingPost(
dto: ApplicationCreatedDTO,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/checked-supplier/application/create',
            body: dto,
        });
    }

    /**
     * appDescUpdate
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static appDescUpdateUsingPost(
dto: ApplicationDescriptionUpdateDTO,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/checked-supplier/application/desc/update',
            body: dto,
        });
    }

    /**
     * appUpdate
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static appUpdateUsingPost(
dto: ApplicationUpdateDTO,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/checked-supplier/application/update',
            body: dto,
        });
    }

    /**
     * getAll
     * @param checkedUser 
     * @param inn 
     * @param organizationId 
     * @param organizationName 
     * @param page page
     * @param size size
     * @param status 
     * @returns ApiResultOfPageOfCheckedSupplierDto OK
     * @throws ApiError
     */
    public static getAllUsingGet(
checkedUser?: string,
inn?: string,
organizationId?: number,
organizationName?: string,
page?: number,
size: number = 20,
status?: 'NEW' | 'IN_PROCESS' | 'COMPLETED',
): CancelablePromise<ApiResultOfPageOfCheckedSupplierDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/checked-supplier/get-all',
            query: {
                'checkedUser': checkedUser,
                'inn': inn,
                'organizationId': organizationId,
                'organizationName': organizationName,
                'page': page,
                'size': size,
                'status': status,
            },
        });
    }

    /**
     * getInfo
     * @returns ApiResultOfInfoDTO OK
     * @throws ApiError
     */
    public static getInfoUsingGet(): CancelablePromise<ApiResultOfInfoDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/checked-supplier/info',
        });
    }

    /**
     * get
     * @param id id
     * @returns ApiResultOfCheckedSupplierDto OK
     * @throws ApiError
     */
    public static getUsingGet(
id: number,
): CancelablePromise<ApiResultOfCheckedSupplierDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/checked-supplier/{id}',
            path: {
                'id': id,
            },
        });
    }

}
