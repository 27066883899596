import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import example from '../../assets/images/example.png'
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
const DownloadReport = () => {
    const {t} = useTranslation()
    const loc = useLocation()
    const date = loc.pathname.substring(loc.pathname.lastIndexOf("date=") + 5);
    // const inn = (loc.pathname.substring(loc.pathname.lastIndexOf("tin=") + 4).split('/', 1));
    const [tin, setTin] = useState<string>('')

    const handleInputChange = (val: string) => {
        setTin(val);
    };

    const downloadReport = async () => {
        try {
            let text = tin.split('').reverse().join('')
            let res = await axios.get(`/api/v1/file/qr?dateTime=${date}&tin=${text}`, {
                responseType: "blob"
            })
            if(res.data) {
                let blob = new Blob([res.data], { type: "application/pdf" });
                let url = URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.target = "_blank";
                a.href = url;
                a.download = `report`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } catch (e: any){
            toast(e.message, {
                type: "error",
            });
        }
    }

    return (
        <>
            <div className="container">
                <div className="card mb-5 mb-xl-10">
                    <div className="card-body py-9">
                        <div className="d-flex flex-stack flex-column align-items-center justify-content-center mb-6 w-100">
                            <div className="d-flex flex-wrap mb-8">
                                <div className="form-floating w-125px position-relative me-4">
                                    <input
                                        type="text"
                                        className="form-control h-45px"
                                        name="captcha"
                                        placeholder={t("enterCode")}
                                        value={tin}
                                        onChange={e => handleInputChange(e.target.value)}
                                    />
                                    <label htmlFor="tin">{t("enterCode")}</label>
                                </div>
                                <button
                                    className="btn btn-primary align-self-center"
                                    onClick={downloadReport}
                                >
                                    {t("download")}
                                </button>
                            </div>
                            <div className="w-100 position-relative d-flex align-items-center justify-content-center">
                                <img className="w-100" src={example} alt="example" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DownloadReport;
