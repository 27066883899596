import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {PaginationProps} from "antd";
import {deleteRole, getAllRoles} from "../../../redux/services/roleService";
import {toast} from "react-toastify";
import {ColumnGroupType, ColumnType} from "antd/lib/table";
import {RoleDTO} from "../../../services/openapi";
import Input from "../../core/Input";
import {SVG} from "../../core/SVG";
import {DeleteIcon, SearchTableIcon} from "../../Icons";
import CreateUpdateRole from "./CreateUpdateRole";
import ConfirmModal from "../../core/ConfirmModal";
import Toolbar from "../../Toolbar";
import CardBody from "../../core/CardBody";
import CustomTable from "../../core/CustomTable";
import {IFilterRole} from "../../../models";

const AllRoles = () => {
    const { t } = useTranslation();

    const [filter, setFilter] = useState<IFilterRole>({
        id: undefined,
        name: ""
    })
    const dispatch = useAppDispatch();

    const { roles, loading, error, totalCount } = useAppSelector((state) => state.role);
    const { userMe } = useAppSelector(
        (state) => state.user
    );
    const [page,setPage] = useState<number>(0)
    const [size,setSize] = useState<number>(25)
    const onChangePagination: PaginationProps['onChange'] = (pageNumber) => {
        setPage(pageNumber-1)
    };
    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current,pageSize) => {
        setPage(current)
        setSize(pageSize)
    };

    useEffect(() => {
        dispatch(getAllRoles({page, size}));
    }, [dispatch, page, size]);

    const handleDelete = async (id: number) => {
        try {
            let res: any = await dispatch(deleteRole(id));
            if (res.payload) {
                if (res.payload.success) {
                    toast(res.payload?.message, {
                        type: "success",
                    });
                    dispatch(getAllRoles({page,size}));
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
        let {name,value} = event.target;
        setFilter((filter) => ({...filter, [name]: value }))
    }

    const handleFilter = async () =>{
        await dispatch(getAllRoles({
            page:0, size,
            name: filter.name
        }))
    }

    const columns: (ColumnGroupType<RoleDTO> | ColumnType<RoleDTO>)[] = [
        {
            title: "№",
            key: "id",
            width: "80px",
            className: "text-center gx-0",
            render: (row) => {
                let index = roles.indexOf(row);
                return page*size+index + 1;
            },
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("code")}</div>
                    <div className="fv-row mb-4">
                        <Input type="text" name="code" value={filter.name}  onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            dataIndex: "code",
            key: "code",
            width: "100%",
        },

        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2"> {t("action")}</div>
                    <div className="fv-row mb-4">
                        <div onClick={handleFilter} className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0">
                            <SVG icon={<SearchTableIcon />} className="svg-icon-1" />
                        </div>
                    </div>
                </div>
            ),
            key: "action",
            fixed: "right",
            width: 200,
            className: "text-center",
            render: (row) => {
                return (
                    <div className="d-flex justify-content-center flex-shrink-0">
                        {
                            userMe.permissions!.map(el => el.code === "ROLE_UPDATE" && <CreateUpdateRole key={el.id} id={row.id} />)
                        }
                        {
                            userMe.permissions!.map(el => el.code === "ROLE_DELETE" && <ConfirmModal
                                key={el.id}
                                btnClass="btn-icon btn-bg-light btn-active-color-primary w-20px h-20px me-1"
                                icon={<SVG icon={<DeleteIcon />} className="svg-icon-2" />}
                                title={t("delete")}
                                handleSubmit={() => handleDelete(row.id)}
                            />)
                        }
                    </div>
                );
            },
        },
    ];

    const data = useMemo(() => roles, [roles]);

    return (
        <>
            <Toolbar children={userMe.permissions!.map(el => el.code === "ROLE_CREATE" && <CreateUpdateRole key={el.id} />)} title={t("roles")} />
            <CardBody
                children={
                    <CustomTable
                        columns={columns}
                        error={error}
                        data={data}
                        loading={loading}
                        page={page}
                        pageSize={size}
                        paginationShowChange={onShowSizeChange}
                        paginationChange={onChangePagination}
                        totalCount={totalCount}
                    />
                }
            />
        </>
    );
};

export default AllRoles;