import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {PaginationProps} from "antd";
import {ColumnGroupType, ColumnType} from "antd/lib/table";
import {EgovErrorResponseDTO} from "../../../services/openapi";
import Input from "../../core/Input";
import {SVG} from "../../core/SVG";
import {SearchTableIcon} from "../../Icons";
import Toolbar from "../../Toolbar";
import CardBody from "../../core/CardBody";
import CustomTable from "../../core/CustomTable";
import {IEgovFilter} from "../../../models";
import {getAllEgovErrors} from "../../../redux/services/settingsService";
import {fieldCheck} from "../../../helpers/fieldCheck";

const EgovError = () => {
    const {t} = useTranslation();

    const dispatch = useAppDispatch();
    const [filter, setFilter] = useState<IEgovFilter>({
        url: ""
    })

    const [page, setPage] = useState<number>(0)
    const [size, setSize] = useState<number>(25)

    const {egovErrors, loading, error, totalCount} = useAppSelector(
        (state) => state.settings
    );

    const onChangePagination: PaginationProps['onChange'] = (pageNumber) => {
        setPage(pageNumber - 1)
    };
    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
        setPage(current)
        setSize(pageSize)
    };

    useEffect(() => {
        dispatch(getAllEgovErrors({page, size}));
    }, [dispatch, page, size]);

    const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
        let {name,value} = event.target;
        setFilter((filter) => ({...filter, [name]: value }))
    }

    const handleFilter = async () =>{
        await dispatch(getAllEgovErrors({
            page:0, size,
            ...fieldCheck(filter),
        }))
    }

    const columns: (
        | ColumnGroupType<EgovErrorResponseDTO>
        | ColumnType<EgovErrorResponseDTO>
        )[] = [
        {
            title: "№",
            key: "id",
            width: "80px",
            className: "text-center gx-0",
            render: (row) => {
                let index = egovErrors.indexOf(row);
                return page * size + index + 1;
            },
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("url")}</div>
                    <div className="fv-row mb-4 d-flex">
                        <Input type="text" name="url"  value={filter.url}  onChange={handleChangeFilter} />
                        <div onClick={handleFilter} className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0">
                            <SVG icon={<SearchTableIcon/>} className="svg-icon-1"/>
                        </div>
                    </div>
                </div>
            ),
            dataIndex: "url",
            key: "url",
            width: "100%",
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("time")}</div>
                    <div className="fv-row mb-4 d-flex">
                        <Input type="text" name="time" disabled/>
                    </div>
                </div>
            ),
            dataIndex: "time",
            key: "time",
            width: "100%",
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("message")}</div>
                    <div className="fv-row mb-4">
                        <Input type="text" name="message" disabled/>
                    </div>
                </div>
            ),
            dataIndex: "message",
            key: "message",
            width: "100%",
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("request")}</div>
                    <div className="fv-row mb-4 min-w-175px">
                        <Input type="text" name="request" disabled/>
                    </div>
                </div>
            ),
            dataIndex: "request",
            key: "request",
            width: "100%",
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("errorResponse")}</div>
                    <div className="fv-row mb-4">
                        <Input type="text" name="errorResponse" className="opacity-0" disabled/>
                    </div>
                </div>
            ),
            dataIndex: "errorResponse",
            key: "errorResponse",
            width: "100%",
        }
    ];

    const data = useMemo(() => egovErrors, [egovErrors]);

    return (
        <>
            <Toolbar title={t("egovError")} />
            <CardBody
                children={
                    <CustomTable
                        columns={columns}
                        error={error}
                        data={data}
                        loading={loading}
                        page={page}
                        pageSize={size}
                        paginationShowChange={onShowSizeChange}
                        paginationChange={onChangePagination}
                        totalCount={totalCount}
                    />
                }
            />
        </>
    );
};

export default EgovError;
