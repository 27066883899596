import React, {ChangeEvent, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {clearUser, setUser} from "../../../redux/slices/user";
import {getAllRoles} from "../../../redux/services/roleService";
import {createUser, getAllUsers, getOneUser, updateUser} from "../../../redux/services/user";
import {UserCreateUpdateDTO} from "../../../services/openapi";
import {toast} from "react-toastify";
import {SVG} from "../../core/SVG";
import {EditIcon} from "../../Icons";
import {Modal, Select, Spin} from "antd";
import InputGroup from "../../core/InputGroup";
import {getAllOrganizations} from "../../../redux/services/organizationService";


type Props = {
    id?: number;
};

const CreateUpdateUser: React.FC<Props> = ({ id }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const { loading, user, userMe } = useAppSelector((state) => state.user);
    const { organizations } = useAppSelector((state) => state.organization);
    const { roles } = useAppSelector((state) => state.role);

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(
            setUser({
                name: event.target.name,
                value: event.target.value,
            })
        );
    };

    const changeSelectHandler = (value?: any, name?: string) => {
        dispatch(
            setUser({
                name,
                value,
            })
        );
    };

    const handleOpen = () => {
        setOpen(true);
        dispatch(getAllOrganizations({page: 0, size: 1000 }));
        dispatch(getAllRoles({ size: 1000 }));
        if (id) {
            dispatch(getOneUser(id));
        }
    };
    const handleClose = () => {
        setOpen(false);
        dispatch(clearUser());
    };

    const handleSubmit = async () => {
        try {
            let res: any = await dispatch(
                id
                    ? updateUser(user as UserCreateUpdateDTO)
                    : createUser(user as UserCreateUpdateDTO)
            );
            if (res.payload) {
                if (res.payload.success) {
                    toast(res.payload?.message, {
                        type: "success",
                    });
                    handleClose();
                    dispatch(getAllUsers({page:0, size:25}));
                }
            }
        } catch (e) {
            console.log(e);
        }
    };


    return (
        <>
            <button
                className={`btn ${
                    id
                        ? "btn-icon btn-bg-light btn-active-color-primary me-1 w-20px h-20px"
                        : "btn-sm btn-primary"
                }`}
                onClick={handleOpen}
            >
                {id ? <SVG icon={<EditIcon />} className="svg-icon-2" /> : t("create")}
            </button>
            <Modal
                title={
                    <div className="d-flex align-items-center">
                        {t(`${id ? "updateUser" : "createUser"}`)}
                        {loading ? <Spin className="d-flex ms-2" size="small" /> : ""}
                    </div>
                }
                open={open}
                onCancel={handleClose}
                cancelText={t("cancel")}
                onOk={handleSubmit}
                okText={t("save")}
                width={1000}
            >
                <div className="row row-cols-1  row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                    <div className="col">
                        <InputGroup
                            label={t("firstName")}
                            type="text"
                            name="firstName"
                            required
                            value={user.firstName}
                            onChange={changeHandler}
                        />
                    </div>
                    <div className="col">
                        <InputGroup
                            label={t("lastName")}
                            type="text"
                            name="lastName"
                            required
                            value={user.lastName}
                            onChange={changeHandler}
                        />
                    </div>
                </div>
                <div className="row row-cols-1  row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                    <div className="col">
                        <InputGroup
                            label={t("middleName")}
                            type="text"
                            name="middleName"
                            required
                            value={user.middleName}
                            onChange={changeHandler}
                        />
                    </div>
                    <div className="col">
                        <InputGroup
                            label={t("phone")}
                            type="text"
                            name="phoneNumber"
                            required
                            value={user.phoneNumber}
                            onChange={changeHandler}
                        />
                    </div>

                </div>
                {
                    userMe.role !== "ORGANIZATION" ? <div className="row row-cols-1  row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                        <div className="col">
                            <div className="d-flex list-group">
                                <label className="fs-6 fw-semibold form-label mt-3">
                                    <span className="required">{t("organization")}</span>
                                </label>
                                <div className="fv-row">
                                    <Select
                                        placeholder={t('choose')}
                                        className="w-100 form-control form-select-solid p-0"
                                        allowClear
                                        value={user.organizationId}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        onChange={(e) => changeSelectHandler(e, 'organizationId')}
                                        options={organizations.map((el) => {
                                            return(
                                                {
                                                    value: el.id,
                                                    label: el.name
                                                }
                                            )
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex list-group">
                                <label className="fs-6 fw-semibold form-label mt-3">
                                    <span className="required">{t("role")}</span>
                                </label>
                                <div className="fv-row">
                                    <Select
                                        placeholder={t('choose')}
                                        className="w-100 form-control form-select-solid p-0"
                                        allowClear
                                        value={user.roleId}
                                        onChange={(e) => changeSelectHandler(e, 'roleId')}
                                        options={roles.map((el) => {
                                            return(
                                                {
                                                    value: el.id,
                                                    label: el.code
                                                }
                                            )
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> : ""
                }
                <div className="row row-cols-1  row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                    <div className="col">
                        <InputGroup
                            label={t("password")}
                            type="text"
                            name="password"
                            required
                            value={user.password}
                            onChange={changeHandler}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CreateUpdateUser;