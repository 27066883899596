import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {getAllPermissions} from "../../../redux/services/permissionService";
import {getAllRoles} from "../../../redux/services/roleService";
import {Checkbox, Pagination, PaginationProps, Spin} from "antd";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {RoleControllerService} from "../../../services/openapi";
import {toast} from "react-toastify";
import CardBody from "../../core/CardBody";
import Toolbar from "../../Toolbar";
import Input from "../../core/Input";

const GetAccess = () => {
    const {t} = useTranslation()
    const [page,setPage] = useState<number>(0)
    const [size,setSize] = useState<number>(25)
    const dispatch = useAppDispatch()
    const {permissions, loading,  totalCount} = useAppSelector(state => state.permission)
    const {roles, loading: roleLoading } = useAppSelector(state => state.role)

    useEffect(() => {
        dispatch(getAllPermissions({page,size}))
        dispatch(getAllRoles({size: 1000}))
    }, [dispatch, page, size])

    const onChangePagination: PaginationProps['onChange'] = (pageNumber) => {
        setPage(pageNumber-1)
    };
    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current,pageSize) => {
        setPage(current)
        setSize(pageSize)
    };

    const onChange = async (e: CheckboxChangeEvent, permissionIndex: number, roleIndex: number) => {
        try {
            let res = await RoleControllerService.attachUsingPost(e.target.checked, permissions[permissionIndex].id!, roles[roleIndex].id!)
            if(res.success){
                dispatch(getAllPermissions({page,size}))
                dispatch(getAllRoles({size: 1000}))
                toast(res.message, {
                    type: "success"
                })
            }
        } catch (err: any) {
            console.log('err', JSON.stringify(err))
            toast(err.message, {
                type: "error"
            })
        }
    };

    return (
        <div>
            <Toolbar children={<>
                {loading && roleLoading ? <Spin size="default" /> : ""}
            </>} title={t('access')}/>
            <CardBody
                children={
                    <div className="table-responsive">
                        <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                            <thead>
                            <tr className="fw-bolder text-muted">
                                <th className="w-80px text-center align-middle">
                                    №
                                </th>
                                <th className="w-200px mw-225px">
                                    <Input type="text" name="permissionName" />
                                </th>
                                {
                                    roles.map((role, i) => {
                                        return(
                                            <th className="min-w-100px mw-175px text-center" key={i}>{role.code}</th>
                                        )
                                    })
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                permissions.map((permission, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className="w-80px text-center px-2">
                                                {page * size + index+1}
                                            </td>
                                            <td>
                                                {permission.code}
                                            </td>
                                            {
                                                Array(roles.length).fill("")
                                                    .map((_, i) => {
                                                        return(
                                                            <td key={i} className="text-center">
                                                                <Checkbox checked={permission.roleIds ? Array.from(permission.roleIds).includes((roles[i]?.id as any)) : false } onChange={(e) => onChange(e, index, i)}  />
                                                            </td>
                                                        )
                                                    })
                                            }
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                        <Pagination
                            className="d-flex justify-content-end mt-2 px-4"
                            defaultPageSize={25}
                            current={page+1}
                            pageSize={size}
                            responsive={true}
                            pageSizeOptions={[25,50,100]}
                            total={totalCount}
                            onChange={onChangePagination}
                            onShowSizeChange={onShowSizeChange}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default GetAccess;