import {
  ApplicationCreatedDTO,
  ApplicationUpdateDTO,
  CheckedSupplierControllerService,
  RemoteControllerService
} from "../../services/openapi";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {
  ICreateApp,
  IFilterCheckedSupplier,
  IFilterDubiousOrganizations,
  IFilterUnscrupulousPerformers
} from "../../models";

export const getCaptcha = createAsyncThunk("captcha/get", async () => {
  try {
    const response = await RemoteControllerService.getOwsCaptchaUsingGet();
    return response.data;
  } catch (error: any) {
    toast(error?.body?.errors[0]?.errorMsg || error.message, {
      type: "error",
    });
  }
});

export const getSupplierInfo = createAsyncThunk(
  "supplier/info",
  async (data: {
    inn: string;
    captcha: string;
    captchaId: string;
    jSessionId: string;
  }) => {
    try {
      const response = await RemoteControllerService.getOwsInfoUsingGet(
        data.captcha,
        data.captchaId,
        data.inn,
        data.jSessionId
      );
      console.log("response", response);
      return response.data;
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const getAllCheckedSuppliers = createAsyncThunk(
  "supplier/get-all",
  async (args: IFilterCheckedSupplier) => {
    try {
      const response = await CheckedSupplierControllerService.getAllUsingGet(
        args.checkedUser,
        args.inn,
        args.organizationId,
        args.organizationName,
        (args.page = args.page || 0),
        args.size,
        args.status
      );
      return response.data;
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const getDubiousOrganizations = createAsyncThunk(
  "dubious/get-all",
  async (args: IFilterDubiousOrganizations) => {
    try {
      const response = await RemoteControllerService.getObnalSupplierUsingGet(
        args.city,
        args.founderName,
        args.inn,
        args.organizationName,
        (args.page = args.page || 0),
        args.registerDate,
        (args.size = args.size || 25)
      );
      return response.data;
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const getUnscrupulousPerformers = createAsyncThunk(
  "unscrupulous/get-all",
  async (args: IFilterUnscrupulousPerformers) => {
    try {
      const response = await RemoteControllerService.getUnscrupulousPerformerUsingGet(
        args.organizationName,
        (args.page = args.page || 0),
        (args.size = args.size || 25),
        args.tin,
      );
      return response.data;
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const getOneCheckedSuppliers = createAsyncThunk(
  "supplier/get-one",
  async (id: number) => {
    try {
      const response = await CheckedSupplierControllerService.getUsingGet(id);
      return response.data;
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const createApp = createAsyncThunk(
  "supplier/app/create",
  async ({tin, jSessionId, captcha, captchaId}: ICreateApp) => {
    try {
      return await CheckedSupplierControllerService.sendAppUsingGet(
        captcha, captchaId, jSessionId,
        tin
      );
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);
export const createApplication = createAsyncThunk(
  "supplier/application/create",
  async (tin: ApplicationCreatedDTO) => {
    try {
      const response = await CheckedSupplierControllerService.appCreateUsingPost(tin);
      console.log('response', response)
      return response;
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const updateApplication = createAsyncThunk(
  "supplier/application/update",
  async (data: ApplicationUpdateDTO) => {
    try {
      return await CheckedSupplierControllerService.appUpdateUsingPost(data);
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const completeApplication = createAsyncThunk(
  "supplier/application/create",
  async (id: number) => {
    try {
      return await CheckedSupplierControllerService.appCompleteUsingPost(id);
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);
