export const getCookie = (cname: string) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const setCookie = (
  cname: string,
  cvalue: string,
  path = "/",
  exdays = 90
) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue}; ${expires}; path=${path}`;
};

export const setAllCookies = (me: any, token: string, refreshToken: string) => {
  document.cookie = `user=${JSON.stringify(me)};domain=.aws.uz`;
  document.cookie = `user=${JSON.stringify(me)};domain=localhost`;
  document.cookie = `_token=${token};domain=.aws.uz`;
  document.cookie = `_token=${token};domain=localhost`;
  document.cookie = `_refreshToken=${refreshToken};domain=.aws.uz`;
  document.cookie = `_refreshToken=${refreshToken};domain=localhost`;
};

export const removeAllCookies = () => {
  document.cookie = `user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.aws.uz`;
  document.cookie = `user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=localhost`;
  document.cookie = `_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.aws.uz`;
  document.cookie = `_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=localhost`;
  document.cookie = `_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  document.cookie = `_refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.aws.uz`;
  document.cookie = `_refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=localhost`;
  document.cookie = `_refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
};

export const removeCookie = (cname: string) => {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
