import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IFilterUnscrupulousPerformers} from "../../../models";
import {PaginationProps} from "antd";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import { getUnscrupulousPerformers} from "../../../redux/services/supplierService";
import {fieldCheck} from "../../../helpers/fieldCheck";
import {ColumnGroupType, ColumnType} from "antd/lib/table";
import {ObnalSupplierDto} from "../../../services/openapi";
import Input from "../../core/Input";
import {SVG} from "../../core/SVG";
import {DeleteIcon, SearchTableIcon} from "../../Icons";
import Toolbar from "../../Toolbar";
import CardBody from "../../core/CardBody";
import CustomTable from "../../core/CustomTable";

const UnscrupulousPerformers = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(25);
  const [filter, setFilter] = useState<IFilterUnscrupulousPerformers>({
    organizationName: "",
    tin: "",
  });

  const onChangePagination: PaginationProps["onChange"] = (pageNumber) => {
    setPage(pageNumber - 1);
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPage(current);
    setSize(pageSize);
  };
  const dispatch = useAppDispatch();
  const {  unscrupulousPerformers, unscrupulousTotal, loading, error } =
    useAppSelector((state) => state.supplier);

  useEffect(() => {
    dispatch(getUnscrupulousPerformers({ page, size }));
  }, [dispatch, page, size]);

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    setFilter((filter) => ({ ...filter, [name]: value }));
  };

  const handleFilter = async () => {
    await dispatch(
      getUnscrupulousPerformers({
        page: 0,
        size,
        ...fieldCheck(filter),
      })
    );
  };
  const handleClearFilter = async () => {
    setFilter({
      organizationName: "",
      tin: "",
    });
    await dispatch(
      getUnscrupulousPerformers({
        page: 0,
        size,
      })
    );
  };

  const columns: (
    | ColumnGroupType<ObnalSupplierDto>
    | ColumnType<ObnalSupplierDto>
    )[] = [
    {
      title: "№",
      key: "id",
      width: "80px",
      className: "text-center gx-0",
      render: (row) => {
        let index = unscrupulousPerformers.indexOf(row);
        return page * size + index + 1;
      },
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("tin")}</div>
          <div className="fv-row mb-4 w-150px min-w-100 d-flex">
            <Input
              type="text"
              name="tin"
              value={filter.tin}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "tin",
      key: "tin",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("orgName")}</div>
          <div className="fv-row mb-4 w-150px min-w-100 d-flex">
            <Input
              type="text"
              name="organizationName"
              value={filter.organizationName}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "organizationName",
      key: "organizationName",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("unscrupulous")} </div>
          <div className="fv-row mb-4 w-150px min-w-100">
            <Input
              type="text"
              name="number"
              value={undefined}
              disabled
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "number",
      key: "number",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("entryDate")} </div>
          <div className="fv-row mb-4 w-150px min-w-100">
            <Input
              type="text"
              name="entryDate"
              value={undefined}
              disabled
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "entryDate",
      key: "entryDate",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("exitDate")} </div>
          <div className="fv-row mb-4 w-150px min-w-100 d-flex">
            <div className="fv-row mb-4">
              <div
                onClick={handleClearFilter}
                className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0"
              >
                <SVG icon={<DeleteIcon />} className="svg-icon-1" />
              </div>
            </div>
            <div className="fv-row mb-4">
              <div
                onClick={handleFilter}
                className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0"
              >
                <SVG icon={<SearchTableIcon />} className="svg-icon-1" />
              </div>
            </div>
          </div>
        </div>
      ),
      dataIndex: "exitDate",
      key: "exitDate",
    },
  ];

  const data = useMemo(() => unscrupulousPerformers, [unscrupulousPerformers]);

  return (
    <>
      <Toolbar title={t("unscrupulousPerformerFull")} />
      <CardBody
        children={
          <CustomTable
            columns={columns}
            error={error}
            data={data}
            loading={loading}
            page={page}
            pageSize={size}
            className="table-striped-rows"
            paginationShowChange={onShowSizeChange}
            paginationChange={onChangePagination}
            totalCount={unscrupulousTotal}
          />
        }
      />
    </>
  );
};

export default UnscrupulousPerformers;
