import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
    icon?: JSX.Element;
    btnTitle?: string;
    title?: string;
    children?: JSX.Element;
    btnClass?: string;
    handleSubmit?: (e: React.MouseEvent<HTMLElement>) => void;
    userDropdown?: boolean
};
const ConfirmModal: React.FC<Props> = ({
                                           title,
                                           children,
                                           btnTitle,
                                           icon,
                                           btnClass,
                                           handleSubmit,
                                           userDropdown
                                       }) => {
    const { t } = useTranslation();
    const { confirm } = Modal;

    const showConfirm = () => {
        confirm({
            content: <div>{title}
                {children}
            </div>,
            onOk: handleSubmit,
            cancelText: t("no"),
            okText: t("yes"),
        });
    };

    return (
        userDropdown ? <a href="#/" className="menu-link px-5" onClick={showConfirm}>
            {btnTitle}
        </a> : <button className={["btn", btnClass || ""].join(" ")} onClick={showConfirm}>
            {icon}
            {btnTitle ? <span className="btn-label">{btnTitle}</span> : ""}
        </button>
    );
};

export default ConfirmModal;
