import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "antd/dist/antd.min.css";
import "./index.css";
import App from "./app/App";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { store } from "./redux/store";
import { OpenAPI } from "./services/openapi";
import { getCookie } from "./helpers/cookie";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

OpenAPI.TOKEN = getCookie("_token");


root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  </BrowserRouter>
);
