export const FreeServices = [
  {
    title: 'Налоговый ассистент',
    link: 'https://ai.soliq.uz/'
  },
  {
    title: "Проверка налоговой задолженности контрагента",
    link: "https://soliq.uz/activities/debtor?lang=ru",
  },
  {
    title: "Список крупных налогоплательщиков",
    link: "https://soliq.uz/activities/large-taxpayers",
  },
  {
    title: "Статус плательщиков НДС",
    link: "https://my.soliq.uz/nds/home?lang=ru",
  },
  // {
  //     title: "Коэффициент налогового разрыва",
  //     link: "https://soliq.uz/activities/tax-risk-analysis?lang=ru",
  // },
  {
    title: "Должная осмотрительность",
    link: "https://soliq.uz/activities/tax-risk-analysis",
  },
  {
    title: "Проверка исполнительных дел",
    link: "https://mib.uz",
  },
  {
    title: 'Проверка запрета на автомашину',
    link: 'https://mib.uz/cab',
  },
  {
    title: 'Проверка запрета на недвижимость',
    link: 'https://www.mib.uz/crb',
  },
  {
    title: "Выписки исполнительных дел",
    link: "https://mib.uz",
  },
  {
    title: "Сведения о предпринимательстве из ЕПИГУ",
    link: "https://new.birdarcha.uz/check-register",
  },
  {
    title: "Сведения из ЕГРПО",
    link: "https://registr.stat.uz/ru/",
  },
  {
    title: "Вид деятельности",
    link: "https://new.birdarcha.uz/check-register",
  },
  {
    title: "Сообщение о реорганизации",
    link: "https://fo.birdarcha.uz/reorganization/notification/list",
  },
  {
    title: "Юридические лица переведенные в недействующий статус",
    link: "https://new.birdarcha.uz/notifications/LIQUIDATION",
  },
  {
    title: "Государственный реестр прав на объекты недвижимости",
    link: "https://davreestr.uz/",
  },
  {
    title: "Онлайн отслеживание административных дел",
    link: "https://my.sud.uz/pages/monitoring/adm.html",
  },
  {
    title: "Онлайн отслеживание экономических дел",
    link: "https://my.sud.uz/pages/monitoring/economical.html",
  },
  {
    title: "Онлайн отслеживание гражданских дел",
    link: "https://my.sud.uz/pages/monitoring/civil.html",
  },
  {
    title: "Онлайн отслеживание уголовных дел ",
    link: "https://my.sud.uz/pages/monitoring/criminal.html",
  },
  {
    title: "Онлайн отслеживание административных правонарушений",
    link: "https://my.sud.uz/pages/monitoring/adm-huquqbuzarlik.html",
  },
  {
    title: "Электронный рейтинг строительно-подрядных организаций",
    link: "https://reyting.mc.uz/",
  },
  {
    title: "Поиск зарегистрированных лицензии в реестре Министерства Юстиции",
    link: "https://licenses.uz/registry",
  },
  {
    title: "Поиск зарегистрированных лицензии в Министерстве Транспорта",
    link: "http://info.mintrans.uz/#/info/registerOfLicensee",
  },
  // {
  //     title: "Залоговый реестр",
  //     link: "https://www.garov.uz/ru/site/search",
  // },
  {
    title: "Реестр национальной системы <br/>  аккредитации",
    link: "https://akkred.uz/reestr?status=&typeOrgan=&page=1&searchInn=&region=",
  },
  {
    title: "Реестр оценщиков",
    link: "https://www.obemj.uz/spisok-clenov",
  },
  {
    title: "Решения судов по <br/>  экономическим делам",
    link: "https://public.sud.uz/report/ECONOMIC",
  },
  {
    title: "Решения судов по <br/>  административным делам",
    link: "https://publication.sud.uz/#!/sign/administrative",
  },
  {
    title: "Решения судов по <br/> гражданским делам",
    link: "https://public.sud.uz/report/CIVIL",
  },
  {
    title: `Решения судов по <br/> уголовным делам`,
    link: "https://public.sud.uz/report/CRIMINAL",
  },
  {
    title: "Узнать свой ИНН или ПИНФЛ",
    link: "https://my3.soliq.uz/individual/cabinet/find-tin/home    ",
  },
  {
    title: "Проверить недобросовестного участника <br/> (e-auksion)",
    link: "https://e-auksion.uz/check-blacklist",
  },
  {
    title: "Поиск административного протокола <br/> (e-jarima)",
    link: "https://ejarima.uz/search-admin",
  },
  {
    title: "Единый реестр недобросовестных исполнителей",
    link: "https://xarid.uzex.uz/info/unfair-executor",
  },
  {
    title: "Госреестр сертификатов <br/> соответствия",
    link: "http://sert2.standart.uz/site/index?language=ru",
  },
  {
    title: "Проверка достоверности <br/> документов ",
    link: "https://new.birdarcha.uz/check-document",
  },
  {
    title: "Разыскиваемые лица <br/> по линии БПИ",
    link: "https://mib.uz/wanted",
  },
  {
    title: "Рейтинг устойчивости субъектов предпринимательства ",
    link: "https://chamber.uz/contractor-rating",
  },
];
