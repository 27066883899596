import {createAsyncThunk} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {DictionaryControllerService} from "../../services/openapi";

export const getCurrencyType = createAsyncThunk(
  "dictionary/currency",
  async () => {
    try {
      return await DictionaryControllerService.getCurrencyTypeUsingGet();
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);
