import {createAsyncThunk} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {RemoteControllerService} from "../../services/openapi";
import {IEgovFilter} from "../../models";

export const getAllEgovRequests = createAsyncThunk(
    "egov/request/get-all",
    async (args: IEgovFilter) => {
        try {
            const response = await RemoteControllerService.getAllRequestsUsingGet(
                (args.page = args.page || 0),
                (args.size = args.size || 25),
                args.url
            );
            return response.data;
        } catch (error: any) {
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
);

export const getAllEgovErrors = createAsyncThunk(
    "egov/error/get-all",
    async (args: IEgovFilter) => {
        try {
            const response = await RemoteControllerService.getAllErrorResponsesUsingGet(
                (args.page = args.page || 0),
                (args.size = args.size || 25),
                args.url
            );
            return response.data;
        } catch (error: any) {
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
);
