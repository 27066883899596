import {Outlet, useLocation} from "react-router-dom";
import { Header, Navbar } from "../components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useAppDispatch} from "../hooks";
import {checkAttempt} from "../redux/services/user";

const Layout = () => {
  const [isDrawer, setIsDrawer] = useState<boolean>(false);
  const [onDrawer, setOnDrawer] = useState<string>("");
  const { t } = useTranslation();

  const location = useLocation()

  const dispatch = useAppDispatch()



  useEffect(() => {
    dispatch(checkAttempt())
  }, [dispatch,location])


  useEffect(() => {
    let w = window.innerWidth;
    if (w) {
      if (w < 992) {
        setIsDrawer(true);
      } else {
        setIsDrawer(false);
      }
    }
  }, []);

  const handleCloseAside = () => {
    setOnDrawer("");
  };

  const handleOpenAside = () => {
    setOnDrawer("drawer-on");
  };

  return (
    <main className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed">
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Navbar
            isDrawer={isDrawer}
            onDrawer={onDrawer}
            handleCloseAside={handleCloseAside}
          />
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <Header handleOpenAside={handleOpenAside} />
            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content"
            >
              <div
                className="post d-flex flex-column-fluid flex-wrap"
                id="kt_post"
              >
                <Outlet />
              </div>
              <div id="kt_app_footer" className="app-footer ">
                <div className="app-container  container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3 ">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2022©</span>
                    <a
                      href="https://keenthemes.com"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-800 text-hover-primary"
                    >
                      Smart Info Data
                    </a>
                  </div>
                  <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                      <a
                        href="https://www.kontragent.uz/"
                        target="_blank"
                        rel="noreferrer"
                        className="menu-link px-2"
                      >
                        {t("goToSite")}
                      </a>
                    </li>

                    <li className="menu-item">
                      <a
                        href="https://www.instagram.com/kontragent.uz/?igsh=ang4d2x5enJ4ODhw"
                        target="_blank"
                        rel="noreferrer"
                        className="menu-link px-2"
                      >
                        Instagram
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Layout;
