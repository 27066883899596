import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {Input, PaginationProps} from "antd";
import {deleteMessage, getAllMessages} from "../../../redux/services/messageService";
import {toast} from "react-toastify";
import {ColumnGroupType, ColumnType} from "antd/lib/table";
import {MessageDTO} from "../../../services/openapi";
import {IFilterMessage} from "../../../models";
import {SVG} from "../../core/SVG";
import {DeleteIcon, SearchTableIcon} from "../../Icons";
import CreateUpdateMessage from "./CreateUpdateMessage";
import ConfirmModal from "../../core/ConfirmModal";
import Toolbar from "../../Toolbar";
import CardBody from "../../core/CardBody";
import CustomTable from "../../core/CustomTable";

const AllMessages = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [filter,setFilter] = useState<IFilterMessage>({
        id: undefined,
        key: "",
        textEn: "",
        textRu: "",
        textUz: ""
    })
    const { messages, loading, error, totalCount } = useAppSelector((state) => state.message);
    const { userMe } = useAppSelector(
        (state) => state.user
    );
    const [page,setPage] = useState<number>(0)
    const [size,setSize] = useState<number>(25)
    const onChangePagination: PaginationProps['onChange'] = (pageNumber) => {
        setPage(pageNumber-1)
    };
    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current,pageSize) => {
        setPage(current)
        setSize(pageSize)
    };


    useEffect(() => {
        dispatch(getAllMessages({page, size}));
    }, [dispatch, page, size]);

    const handleDelete = async (id: number) => {
        try {
            let res: any = await dispatch(deleteMessage(id));
            if (res.payload) {
                if (res.payload.success) {
                    toast(res.payload?.message, {
                        type: "success",
                    });
                    dispatch(getAllMessages({page,size}));
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
        let {name,value} = event.target;
        setFilter((filter) => ({...filter, [name]: value }))
    }

    const handleFilter = async () => {
        await dispatch(getAllMessages({
            page:0,size
        }))
    }

    const columns: (ColumnGroupType<MessageDTO> | ColumnType<MessageDTO>)[] = [
        {
            title: "№",
            key: "id",
            width: "80px",
            className: "text-center gx-0",
            render: (row) => {
                let index = messages.indexOf(row);
                return page*size + index + 1;
            },
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("key")}</div>
                    <div className="fv-row mb-4 w-175px min-w-100">
                        <Input type="text" name="key" value={filter.key} onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            dataIndex: "key",
            key: "key",
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start">РУ</div>
                    <div className="fv-row mb-4">
                        <Input type="text" name="textRu" value={filter.textRu} onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            dataIndex: "textRu",
            key: "textRu",
            width: 350
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start">O'Z</div>
                    <div className="fv-row mb-4">
                        <Input type="text" name="textUz" value={filter.textUz} onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            dataIndex: "textUz",
            key: "textUz",
            width: 320
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> EN</div>
                    <div className="fv-row mb-4">
                        <Input type="text" name="textEn" value={filter.textEn} onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            dataIndex: "textEn",
            key: "textEn",
            width: 320
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-center"> {t("action")}</div>
                    <div className="fv-row mb-4">
                        <div onClick={handleFilter} className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0">
                            <SVG icon={<SearchTableIcon />} className="svg-icon-1" />
                        </div>
                    </div>
                </div>
            ),
            key: "action",
            fixed: "right",
            width: 100,
            className: "text-center ps-0",
            render: (row) => {
                return (
                    <div className="d-flex justify-content-center flex-shrink-0">
                        {
                            userMe.permissions!.map(el => el.code === "MESSAGE_UPDATE" && <CreateUpdateMessage
                                key={el.id}
                                id={row.id}
                                page={page}
                                size={size}
                            />)
                        }
                        {
                            userMe.permissions!.map(el => el.code === "MESSAGE_DELETE" &&  <ConfirmModal
                                key={el.id}
                                btnClass="btn-icon btn-bg-light btn-active-color-primary w-20px h-20px me-1"
                                icon={<SVG icon={<DeleteIcon />} className="svg-icon-2" />}
                                title={t("delete")}
                                handleSubmit={() => handleDelete(row.id)}
                            />)
                        }
                    </div>
                );
            },
        },
    ];

    const data = useMemo(() => messages, [messages]);


    return (
        <>
            <Toolbar children={userMe.permissions!.map(el => el.code === "MESSAGE_CREATE" && <CreateUpdateMessage key={el.id} />)} title={t("messages")} />
            <CardBody
                children={
                    <CustomTable
                        columns={columns}
                        error={error}
                        data={data}
                        loading={loading}
                        page={page}
                        pageSize={size}
                        paginationShowChange={onShowSizeChange}
                        paginationChange={onChangePagination}
                        totalCount={totalCount}
                    />
                }
            />
        </>
    );
};

export default AllMessages;