import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "../helpers/cookie";

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();

  let token = getCookie("_token");


  return token ? children : <Navigate to="/login" state={{ from: location }} />;
};
