import { ColumnGroupType, ColumnType } from "antd/lib/table";
import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {deleteOrganization, getAllOrganizations} from "../../../redux/services/organizationService";
import { OrganizationDTO } from "../../../services/openapi";
import CardBody from "../../core/CardBody";
import CustomTable from "../../core/CustomTable";
import CustomModal from "../../core/Modal";
import { SVG } from "../../core/SVG";
import {
  AddressIcon,
  DeleteIcon,
  EmailIcon,
  SearchTableIcon,
} from "../../Icons";
import Toolbar from "../../Toolbar";
import {IFilterOrganization} from "../../../models";
import {PaginationProps} from "antd";
import {toast} from "react-toastify";
import Input from "../../core/Input";
import ConfirmModal from "../../core/ConfirmModal";
import CreateUpdateOrganization from "./CreateUpdateOrganization";
import {fieldCheck} from "../../../helpers/fieldCheck";

const AllOrganizations = () => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const { organizations, loading, error, totalCount } = useAppSelector(
        (state) => state.organization
    );
    const { userMe} = useAppSelector(
        (state) => state.user
    );
    const [page,setPage] = useState<number>(0)
    const [size,setSize] = useState<number>(25)

    const [filter, setFilter] = useState<IFilterOrganization>({
        email: "",
        address: "",
        name: "",
        inn: "",
        phone: "",
        activity: ""
    })

    const onChangePagination: PaginationProps['onChange'] = (pageNumber) => {
        setPage(pageNumber-1)
    };
    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current,pageSize) => {
        setPage(current)
        setSize(pageSize)
    };


    useEffect(() => {
        dispatch(getAllOrganizations({page, size}));
        // dispatch(getAllBusinessDirection({size: 10000}))
    }, [dispatch, page, size]);

    const handleDelete = async (id: number) => {
        try {
            let res: any = await dispatch(deleteOrganization(id));
            if (res.payload) {
                if (res.payload.success) {
                    toast(res.payload?.message, {
                        type: "success",
                    });
                    dispatch(getAllOrganizations({page, size}));
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
        let {name,value} = event.target;
        setFilter((filter) => ({...filter, [name]: value }))
    }

    const handleFilter = async () => {
        await dispatch(getAllOrganizations({
            page:0, size,
            ...fieldCheck(filter)
        }))
    }

    const handleClearFilter = async () => {
        setFilter({
            email: "",
            name: "",
            address: "",
            phone: "",
            inn: "",
            activity: ""
        });
        await dispatch(
          getAllOrganizations({
              page: 0,
              size,
          })
        );
    };

    const columns: (
        | ColumnGroupType<OrganizationDTO>
        | ColumnType<OrganizationDTO>
        )[] = [
        {
            title: "№",
            key: "id",
            width: "80px",
            className: "text-center gx-0",
            render: (row) => {
                let index = organizations.indexOf(row);
                return page*size+index + 1;
            },
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("orgName")}</div>
                    <div className="fv-row mb-4 w-200px min-w-100">
                        <Input type="text" name="name" value={filter.name} onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            dataIndex: "name",
            key: "name",
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-center"> {t("tin")}</div>
                    <div className="fv-row mb-4 w-100px min-w-100">
                        <Input type="text" name="inn" value={filter.inn} onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            dataIndex: "inn",
            key: "inn",
            className: "text-center"
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("activity")}</div>
                    <div className="fv-row mb-4 w-150px min-w-100">
                        <Input type="text" name="activity" value={filter.activity} onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            dataIndex: "activity",
            key: "activity",
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-center"> {t("address")}</div>
                    <div className="fv-row mb-4 w-100px min-w-100">
                        <Input type="text" name="address" value={filter.address} onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            dataIndex: "address",
            key: "address",
            className: "text-center",
            render: (address) => {
                return (
                    <CustomModal
                        title={t("address")}
                        icon={<AddressIcon />}
                        btnSize="w-20px h-20px"
                        btnClass="btn-icon btn-active-light-primary"
                        hiddenOK={true}
                        children={<div>{address}</div>}
                    />
                );
            },
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-center"> {t("phone")}</div>
                    <div className="fv-row mb-4 w-125px min-w-100">
                        <Input type="text" name="phone" value={filter.phone} onChange={handleChangeFilter}  />
                    </div>
                </div>
            ),
            dataIndex: "phone",
            key: "phone",
            className: "text-center",
        },
        {
            title: (
                <div className="d-flex list-group ">
                    <div className="mt-4 mb-2 text-center"> {t("email")}</div>
                    <div className="fv-row mb-4 w-100px min-w-100">
                        <Input type="text" name="email" value={filter.email} onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            dataIndex: "email",
            key: "email",
            className: "text-center",
            render: (email) => {
                return (
                    <CustomModal
                        title={t("email")}
                        icon={<EmailIcon />}
                        btnSize="w-20px h-20px"
                        hiddenOK={true}
                        btnClass="btn-icon btn-active-light-primary"
                        children={<div>{email}</div>}
                    />
                );
            },
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-center"> {t("plan")}</div>
                    <div className="fv-row mb-4 w-125px min-w-100">
                        <Input type="text" name="plan" disabled value={undefined} onChange={handleChangeFilter}  />
                    </div>
                </div>
            ),
            dataIndex: "tariffType",
            key: "tariffType",
            className: "text-center",
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("requestsCount")}</div>
                    <div className="fv-row mb-4 w-175px min-w-150">
                        <Input type="text" name="attemptCount" disabled value={undefined} onChange={handleChangeFilter}  />
                    </div>
                </div>
            ),
            dataIndex: "attemptCount",
            key: "attemptCount",
            className: "text-center",
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2"> {t("action")}</div>
                    <div className="fv-row mb-4 d-flex">
                        <div className="fv-row mb-4">
                            <div
                              onClick={handleClearFilter}
                              className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0"
                            >
                                <SVG icon={<DeleteIcon />} className="svg-icon-1" />
                            </div>
                        </div>
                        <div onClick={handleFilter} className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0">
                            <SVG icon={<SearchTableIcon />} className="svg-icon-1" />
                        </div>
                    </div>
                </div>
            ),
            key: "action",
            fixed: "right",
            width: 100,
            className: "text-center",
            render: (row) => {
                return (
                    <div className="d-flex justify-content-center flex-shrink-0">
                        {
                            userMe.permissions!.map(el => el.code === "ORGANIZATION_UPDATE" &&  <CreateUpdateOrganization key={el.id} id={row.id} />)
                        }
                        {
                            userMe.permissions!.map(el => el.code === "ORGANIZATION_DELETE" &&  <ConfirmModal
                                key={el.id}
                                btnClass="btn-icon btn-bg-light btn-active-color-primary w-20px h-20px me-1"
                                icon={<SVG icon={<DeleteIcon />} className="svg-icon-2" />}
                                title={t("delete")}
                                handleSubmit={() => handleDelete(row.id)}
                            />)
                        }
                    </div>
                );
            },
        },
    ];

    const data = useMemo(() => organizations, [organizations]);

    return (
        <>
            <Toolbar
                children={userMe.permissions!.map(el => el.code === "ORGANIZATION_CREATE" &&  <CreateUpdateOrganization key={el.id} />)}
                title={t("organizations")}
            />
            <CardBody
                children={
                    <CustomTable
                        columns={columns}
                        error={error}
                        data={data}
                        loading={loading}
                        page={page}
                        pageSize={size}
                        paginationShowChange={onShowSizeChange}
                        paginationChange={onChangePagination}
                        totalCount={totalCount}
                    />
                }
            />
        </>
    );
};

export default AllOrganizations;
