import { Spin } from "antd";
import { ChangeEvent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CardBody from "../../core/CardBody";
import Toolbar from "../../Toolbar";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  createApp,
  getAllCheckedSuppliers,
  getCaptcha,
} from "../../../redux/services/supplierService";
import { checkAttempt } from "../../../redux/services/user";
import CheckedSuppliersPage from "../CheckedSuppliersPage";
import { toast } from "react-toastify";
import { ReloadIcon, WarningIcon } from "../../Icons";
import { SVG } from "../../core/SVG";
import { ICreateApp } from "../../../models";

const CheckSupplier = () => {
  const [form, setForm] = useState<ICreateApp>({
    captcha: "",
    tin: "",
    captchaId: "",
    jSessionId: "",
  });

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { sendLoader, captcha } = useAppSelector((state) => state.supplier);
  const { attempt } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(getCaptcha());
  }, [dispatch]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    if (name === "inn") {
      if (value.length > event.target.maxLength) {
        value = event.target.value.slice(0, event.target.maxLength);
      }
    }
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const updateTable = () => {
    dispatch(getAllCheckedSuppliers({ page: 0, size: 25 }));
  };

  const handleSubmit = async () => {
    try {
      let res: any = await dispatch(
        createApp({
          tin: form.tin,
          captcha: form.captcha,
          jSessionId: captcha.sessionId,
          captchaId: captcha.captchaId,
        })
      );
      if (res.payload) {
        setForm({
          tin: "",
          captcha: "",
        });
        toast(res.payload.message, {
          type: "success",
        });
        dispatch(
          getAllCheckedSuppliers({
            page: 0,
            size: 25,
          })
        );
        dispatch(checkAttempt());
      }
    } catch (err: any) {
      console.log(err.message, "ggg");
    }
  };

  // const relBandText = (taxGap: any) => {
  //   if (taxGap! >= 0.0 && taxGap! <= 0.2) {
  //     return "Высокая";
  //   }
  //   if (taxGap! >= 0.21 && taxGap! <= 0.4) {
  //     return "Средняя";
  //   }
  //   if (taxGap! >= 0.41 && taxGap! <= 0.6) {
  //     return "Низкая";
  //   }
  //   if (taxGap! >= 0.61 && taxGap! <= 1) {
  //     return "Сомнительная";
  //   }
  // };

  let date = new Date();

  return (
    <>
      <Toolbar
        children={
          <div className="d-flex flex-column">
            {t("left")}: {attempt?.attemptCount} {t("requests")}
            <span>
              {t("expiredDate")} : {attempt?.expireDate}
            </span>
          </div>
        }
        title={t("check")}
      />
      <CardBody
        children={
          <div>
            <div className="d-flex flex-stack mb-6 w-100">
              <div className="d-flex flex-wrap">
                <div className="form-floating w-125px me-6">
                  <input
                    type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    maxLength={9}
                    className="form-control h-45px"
                    name="tin"
                    placeholder={t("tin")}
                    value={form.tin}
                    onChange={changeHandler}
                    onWheel={(e) => e.currentTarget.blur()}
                    onKeyDown={(event) => {
                      if (event.which === 38 || event.which === 40) {
                        event.preventDefault();
                      }
                    }}
                    autoComplete="new-password"
                  />
                  <label htmlFor="tin">{t("tin")}</label>
                </div>
                <div className="d-flex me-6 d-flex flex-center">
                  <div className="bg-secondary rounded-2 d-flex w-125px flex-center h-45px">
                    <img src={captcha?.imgSrc} alt="captcha" />
                  </div>
                  <div
                    className="btn btn-icon btn-text ms-6 btn-bg-light btn-icon-success"
                    onClick={() => dispatch(getCaptcha())}
                  >
                    <SVG icon={<ReloadIcon />} className="svg-icon-2" />
                  </div>
                </div>
                <div className="form-floating w-125px position-relative">
                  <input
                    type="text"
                    className="form-control h-45px"
                    name="captcha"
                    placeholder={t("enterCode")}
                    value={form.captcha}
                    onChange={changeHandler}
                  />
                  <label htmlFor="tin">{t("enterCode")}</label>
                </div>
                <div
                  onClick={handleSubmit}
                  className="btn btn-icon-primary btn-text-primary btn-bg-light btn-active-color-primary  mb-0 ms-2"
                >
                  {t("sendApplication")}
                </div>
                <div className="d-flex align-items-center ps-1">
                  {sendLoader && <Spin />}
                </div>
              </div>
            </div>
            <div className="mb-2 w-100">
              <div className="d-flex w-100 align-items-center justify-content-between">
                <div className="d-flex">
                  <span className="svg-icon svg-icon-2 svg-icon-success">
                    <SVG icon={<WarningIcon />} />
                  </span>
                  {t("actualOn")} {date.getDate() < 10 ? "0" : ""}
                  {date.getDate()}.{date.getMonth() + 1 < 10 ? "0" : ""}
                  {Number(date.getMonth()) + 1}.{date.getFullYear()}{" "}
                </div>
                <button
                  className="btn btn-icon btn-bg-light btn-active-color-primary me-1 w-40px h-40px"
                  onClick={updateTable}
                >
                  <SVG icon={<ReloadIcon />} className="svg-icon-2" />
                </button>
              </div>
            </div>
            <CheckedSuppliersPage />
          </div>
        }
      />
    </>
  );
};

export default CheckSupplier;
