import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {PaginationProps, Select} from "antd";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {deleteUser, getAllUsers, getUserActive} from "../../../redux/services/user";
import {getAllRoles} from "../../../redux/services/roleService";
import {toast} from "react-toastify";
import {ColumnGroupType, ColumnType} from "antd/lib/table";
import {UserDTO} from "../../../services/openapi";
import {DeleteIcon, SearchTableIcon} from "../../Icons";
import {SVG} from "../../core/SVG";
import CreateUpdateUser from "./CreateUpdateUser";
import ConfirmModal from "../../core/ConfirmModal";
import Toolbar from "../../Toolbar";
import CardBody from "../../core/CardBody";
import CustomTable from "../../core/CustomTable";
import {IFilterUser} from "../../../models";
import Input from "../../core/Input";
import {getAllOrganizations} from "../../../redux/services/organizationService";

const AllUsers = () => {
    const { t } = useTranslation();

    const [page,setPage] = useState<number>(0)
    const [size,setSize] = useState<number>(25)
    const [filter, setFilter] = useState<IFilterUser>({
        fio: '',
        email: '',
        roleId: undefined,
        shortName: '',
        status: '',
        organizationId: undefined,
        phone: ''
    })
    const onChangePagination: PaginationProps['onChange'] = (pageNumber) => {
        setPage(pageNumber-1)
    };
    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current,pageSize) => {
        setPage(current)
        setSize(pageSize)
    };

    const dispatch = useAppDispatch();
    const { users, loading, error, totalCount,userMe, userStatus  } = useAppSelector((state) => state.user);
    const { organizations } = useAppSelector((state) => state.organization);
    const { roles } = useAppSelector((state) => state.role);

    useEffect(() => {
        dispatch(getAllUsers({page, size}));
        dispatch(getAllOrganizations({ size: 1000 }));
        dispatch(getAllRoles({ size: 1000 }));
    }, [dispatch, page, size]);

    const handleDelete = async (id: number) => {
        try {
            let res: any = await dispatch(deleteUser(id));
            if (res.payload) {
                if (res.payload.success) {
                    toast(res.payload?.message, {
                        type: "success",
                    });
                    dispatch(getAllUsers({page, size}));
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleChangeStatus = async (active: boolean, userId: number) => {
        console.log('active change')
        try {
            let res = await dispatch(getUserActive({userId, active}))
            if(res.payload){
                toast(userStatus.message, {
                    type: "success"
                })
                dispatch(getAllUsers({page, size}))
            }
        } catch (err: any){
            console.log(err)
        }
    }

    const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
        let {name,value} = event.target;
        setFilter((filter) => ({...filter, [name]: value }))
    }
    const handleSelectChangeFilter = (name: string, value: string) => {
        setFilter((filter) => ({...filter, [name]: value }))
    }


    const handleFilter = async () => {
        await dispatch(getAllUsers({
            page:0,size
        }))
    }

    const isUserChangeActive = () => {
        let userActive = userMe.permissions!.find(el => el.code === "USER_ACTIVE")
        return !!userActive;
    }
    const isOrganizationView = () => {
        return userMe.role !== "ORGANIZATION";
    }



    const columns: (ColumnGroupType<UserDTO> | ColumnType<UserDTO>)[] = [
        {
            title: "№",
            key: "id",
            width: "80px",
            className: "text-center gx-0",
            render: (row) => {
                let index = users.indexOf(row);
                return page*size + index + 1;
            },
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("shortName")}</div>
                    <div className="fv-row mb-4 w-150px min-w-100">
                        <Input type="text" name="shortName" value={filter.shortName} onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            dataIndex: "shortName",
            key: "shortName"
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("fio")}</div>
                    <div className="fv-row mb-4 w-175px min-w-100">
                        <Input type="text" name="fio" value={filter.fio} onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            key: "fio",
            render: (row) => {
                return (
                    <div>
                        {row.lastName} {row.firstName} {row.middleName}
                    </div>
                );
            }
        },
        ...(isOrganizationView() ? [{
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("organization")}</div>
                    <div className="fv-row mb-4 w-175px min-w-100">
                        <Select
                            placeholder={t('choose')}
                            className="w-100 form-control form-select-sm p-0"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            allowClear
                            onChange={(e) => handleSelectChangeFilter('organizationId', e) }
                            options={organizations.map((el) => {
                                return(
                                    {
                                        value: el.id,
                                        label: el.name
                                    }
                                )
                            })}
                        />
                    </div>
                </div>
            ),
            dataIndex: "organizationName",
            key: "organizationName"
        }] : [] ),
        ...(isOrganizationView() ? [{
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-center"> {t("phone")}</div>
                    <div className="fv-row mb-4 w-125px min-w-100">
                        <Input type="text" name="phone" value={filter.phone} onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            key: "phoneNumber",
            dataIndex: "phoneNumber",
            className: "text-center",
        }] : [{
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-center"> {t("phone")}</div>
                    <div className="fv-row mb-4 w-125px min-w-100">
                        <Input type="text" name="phone" value={filter.phone} onChange={handleChangeFilter} />
                    </div>
                </div>
            ),
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            className: "text-center",
        }]),
    ...(isOrganizationView() ? [{
        title: (
            <div className="d-flex list-group">
                <div className="mt-4 mb-2 text-center"> {t("role")}</div>
                <div className="fv-row mb-4 w-125px">
                    <Select
                        placeholder={t('choose')}
                        className="w-100 form-control form-select-sm p-0"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        allowClear
                        onChange={(e) => handleSelectChangeFilter('roleId', e) }
                        options={roles.map((el) => {
                            return(
                                {

                                    value: el.id,
                                    label: el.code
                                }
                            )
                        })}
                    />
                </div>
            </div>
        ),
        dataIndex: "role",
        key: "role",
        className: "text-center",
    },] : []),
        ...(isUserChangeActive() && isOrganizationView() ? [{
                title: (
                    <div className="d-flex list-group">
                        <div className="mt-4 mb-2 text-center"> {t("status")}</div>
                        <div className="fv-row mb-4 w-100px">
                            <Select
                                placeholder={t('choose')}
                                className="w-100 form-control form-select-sm p-0"
                                allowClear
                                onChange={(e) => handleSelectChangeFilter('status', e) }
                                options={[
                                    {
                                        value: "ACTIVE",
                                        label: "ACTIVE"
                                    },
                                    {
                                        value: "DELETED",
                                        label: "DELETED"
                                    },
                                    {
                                        value: "BLOCKED",
                                        label: "BLOCKED"
                                    }
                                ]}
                            />
                        </div>
                    </div>
                ),
                key: "status",
                className: `text-center`,
                render: (row: UserDTO) => {
                    return(
                        <span className={`badge badge-outline cursor-pointer badge-light-${row.status === "ACTIVE" ? "success" : "danger"}`} onClick={() => handleChangeStatus( row.status !== "ACTIVE", row.id as number)} >
              {row.status}</span>
                    )
                }
            }]  : []
        ),
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2"> {t("action")}</div>
                    <div className="fv-row mb-4">
                        <div onClick={handleFilter} className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0">
                            <SVG icon={<SearchTableIcon />} className="svg-icon-1" />
                        </div>
                    </div>
                </div>
            ),
            key: "action",
            fixed: "right",
            width: 100,
            className: "text-center",
            render: (row) => {
                return (
                    <div className="d-flex justify-content-center flex-shrink-0">
                        {userMe.permissions!.map(el => el.code === "USER_UPDATE" &&  <CreateUpdateUser key={el.id} id={row.id} />)}
                        {
                            userMe.permissions!.map(el => el.code === "USER_DELETE" ? <ConfirmModal
                                key={el.id}
                                btnClass="btn-icon btn-bg-light btn-active-color-primary w-20px h-20px me-1"
                                icon={<SVG icon={<DeleteIcon />} className="svg-icon-2" />}
                                title={t("delete")}
                                handleSubmit={() => handleDelete(row.id)}
                            /> : "")
                        }
                    </div>
                );
            },
        },
    ];

    const data = useMemo(() => users, [users]);
    return (
        <>
            <Toolbar children={userMe.permissions!.map(el => el.code === "USER_CREATE" && <CreateUpdateUser key={el.id} />) } title={t("users")} />
            <CardBody
                children={
                    <CustomTable
                        columns={columns}
                        error={error}
                        data={data}
                        loading={loading}
                        page={page}
                        pageSize={size}
                        paginationShowChange={onShowSizeChange}
                        paginationChange={onChangePagination}
                        totalCount={totalCount}
                    />
                }
            />
        </>
    );
};

export default AllUsers;
