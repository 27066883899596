import React from "react";
type Props = {
  className?: string;
  icon: JSX.Element;
  svgClassName?: string;
};

const SVG: React.FC<Props> = ({ className = "", icon }) => {
  return <span className={`svg-icon ${className}`}>{icon}</span>;
};

export { SVG };
