export const fieldCheck = (value: Record<string, any>) => {
    let keys = Object.keys(value)
    const ret: Record<string, any> = {}
    for (const key of keys) {
        if (value[key]) {
            ret[key] = value[key]
        }
    }
    return ret
}
