/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResultOfOrganizationDTO } from '../models/ApiResultOfOrganizationDTO';
import type { ApiResultOfPageOfOrganizationDTO } from '../models/ApiResultOfPageOfOrganizationDTO';
import type { ApiResultOfstring } from '../models/ApiResultOfstring';
import type { OrganizationClientCreateUpdateDTO } from '../models/OrganizationClientCreateUpdateDTO';
import type { OrganizationCreateUpdateDTO } from '../models/OrganizationCreateUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationControllerService {

    /**
     * create
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static createUsingPost1(
dto: OrganizationCreateUpdateDTO,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/organization',
            body: dto,
        });
    }

    /**
     * update
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static updateUsingPut1(
dto: OrganizationCreateUpdateDTO,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/organization',
            body: dto,
        });
    }

    /**
     * active
     * @param active active
     * @param organizationId organizationId
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static activeUsingPost(
active: boolean,
organizationId: number,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/organization/active',
            query: {
                'active': active,
                'organizationId': organizationId,
            },
        });
    }

    /**
     * clientCreate
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static clientCreateUsingPost(
dto: OrganizationClientCreateUpdateDTO,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/organization/client/create',
            body: dto,
        });
    }

    /**
     * getAll
     * @param address 
     * @param email 
     * @param id 
     * @param inn 
     * @param name 
     * @param page page
     * @param phone 
     * @param size size
     * @returns ApiResultOfPageOfOrganizationDTO OK
     * @throws ApiError
     */
    public static getAllUsingGet2(
address?: string,
email?: string,
id?: number,
inn?: string,
name?: string,
page?: number,
phone?: string,
size: number = 25,
): CancelablePromise<ApiResultOfPageOfOrganizationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/organization/get-all',
            query: {
                'address': address,
                'email': email,
                'id': id,
                'inn': inn,
                'name': name,
                'page': page,
                'phone': phone,
                'size': size,
            },
        });
    }

    /**
     * get
     * @param id id
     * @returns ApiResultOfOrganizationDTO OK
     * @throws ApiError
     */
    public static getUsingGet2(
id: number,
): CancelablePromise<ApiResultOfOrganizationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/organization/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * delete
     * @param id id
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static deleteUsingDelete1(
id: number,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/organization/{id}',
            path: {
                'id': id,
            },
        });
    }

}
