export const formatMoney = (value: any) => {
    if (Number(value) === value && value % 1 !== 0) {
        let parts = value.toFixed(2).toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".");
    } else if (!value) {
        return "";
    } else {
        let parts = value.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".");
    }
}