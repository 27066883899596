import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "../slices/auth";
import organizationReducer from "../slices/organizations";
import supplierReducer from "../slices/supplier";
import userReducer from '../slices/user'
import messageReducer from '../slices/message'
import permissionReducer from '../slices/permission'
import roleReducer from '../slices/role'
import settingsReducer from '../slices/settings'
import dictionaryReducer from '../slices/dictionary'
export const store = configureStore({
  reducer: {
    auth: authReducer,
    organization: organizationReducer,
    supplier: supplierReducer,
    user: userReducer,
    message: messageReducer,
    permission: permissionReducer,
    role: roleReducer,
    settings: settingsReducer,
    dictionary: dictionaryReducer
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
