import { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { Login, ReportFile } from "../pages";
import { PrivateRoute } from "../router/ProtectedRoute";
import { ROUTES } from "../router/routes";
import Layout from "./Layout";
function App() {
  const { accessToken } = useAppSelector((state) => state.auth);

  return (
    <Suspense fallback={null}>
      <Routes>
        {!accessToken ? (
          <>
            <Route path="login" element={<Login />} />
            <Route path="file/qr/:tin/:date" element={<ReportFile />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        ) : (
          <Route path="/" element={<Layout />}>
            {ROUTES.map((route, index) => (
              <Route
                path={route.path}
                key={index}
                element={<PrivateRoute>{route.component}</PrivateRoute>}
              />
            ))}
            <Route path="/" element={<Navigate to="/profile" />} />
            <Route path="*" element={<Navigate to="/profile" />} />
            <Route path="file/qr/:tin/:date" element={<ReportFile />} />
          </Route>
        )}
        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </Suspense>
  );
}

export default App;
