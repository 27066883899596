/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OrganizationDTO = {
    activity?: string;
    address?: string;
    attemptCount?: number;
    email?: string;
    expireDate?: string;
    id?: number;
    inn?: string;
    name?: string;
    phone?: string;
    site?: string;
    status?: OrganizationDTO.status;
    tariffType?: OrganizationDTO.tariffType;
};

export namespace OrganizationDTO {

    export enum status {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
    }

    export enum tariffType {
        BRONZE = 'BRONZE',
        SILVER = 'SILVER',
        GOLD = 'GOLD',
        PLATINUM = 'PLATINUM',
    }


}
