import React, { ChangeEvent, useState } from "react";
import instance from "../../axios";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ChangeInfo } from "../../models";
import Toolbar from "../Toolbar";
import { Modal, Spin } from "antd";
import InputGroup from "../core/InputGroup";
import { DotIcon, EditIcon, LocationIcon } from "../Icons";
import { SVG } from "../core/SVG";
import user from "../../assets/media/avatars/blank.png";
import { toast } from "react-toastify";
import { getUserMe } from "../../redux/services/user";
import { UserControllerService } from "../../services/openapi";
const ProfilePage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { userMe, attempt } = useAppSelector((state) => state.user);
  const ref = React.createRef<HTMLInputElement>();
  const [upload, setUpload] = useState<boolean>(false);
  const [form, setForm] = useState<ChangeInfo>({
    avatarId: undefined,
    avatarUrl: undefined,
    email: undefined,
    newPassword: undefined,
    oldPassword: undefined,
    repeatNewPassword: undefined,
  });
  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(true);
    setForm({
      email: userMe?.organization?.email,
    });
  };
  const handleClose = () => {
    setShow(false);
    setForm({
      avatarId: undefined,
      avatarUrl: userMe?.avatarUrl,
      email: userMe?.organization?.email,
      newPassword: "",
      oldPassword: "",
      repeatNewPassword: "",
    });
  };

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    setUpload(true);
    if (!event.target.files) return;
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    try {
      let res = await instance.post("/api/v1/file", formData);
      if (res) {
        if (ref.current) {
          ref.current.value = "";
        }
        setUpload(false);
        setForm((prev) => ({
          ...prev,
          [event.target.name]: res?.data?.data?.id,
        }));
        setForm((prev) => ({ ...prev, avatarUrl: res?.data?.data?.url }));
      }
    } catch (err: any) {
      console.log(err);
      setUpload(false);
      toast(err.response.data.errors[0].errorMsg, {
        type: "error",
      });
    }
  };

  const handleSubmit = async () => {
    try {
      let res = await UserControllerService.changeInfoUsingPost(
        form.avatarId && form.avatarId,
        form.email && form.email,
        form.newPassword && form.newPassword,
        form.oldPassword && form.oldPassword,
        form.repeatNewPassword && form.repeatNewPassword
      );
      if (res.success) {
        handleClose();
        setForm({
          avatarId: undefined,
          avatarUrl: userMe.avatarUrl || "",
          email: userMe?.organization?.email,
          newPassword: "",
          oldPassword: "",
          repeatNewPassword: "",
        });
        toast(res.message, {
          type: "success",
        });
        await dispatch(getUserMe());
      }
    } catch (err: any) {
      toast(
        err.body.errors[0].errorMsg ? err.body.errors[0].errorMsg : err.message,
        {
          type: "error",
        }
      );
      console.log(
        err.body.errors[0].errorMsg ? err.body.errors[0].errorMsg : err.message
      );
    }
  };

  return (
    <>
      <Toolbar title={t("profile")} />
      <div className="container">
        <div className="card mb-5 mb-xl-10">
          <div className="card-body pt-9 pb-0">
            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
              <div className="me-7 mb-4">
                <div className="symbol symbol-100px symbol-lg-150px w-100px h-100px w-lg-150px h-lg-150px symbol-fixed position-relative">
                  <div className="image-input image-input-outline w-100 h-100">
                    <img
                      className="avatar-img"
                      src={
                        userMe?.avatarUrl !== undefined &&
                        userMe?.avatarUrl !== null
                          ? `${instance.defaults.baseURL}/api/v1/file${userMe?.avatarUrl}`
                          : user
                      }
                      alt={
                        userMe?.firstName &&
                        userMe?.lastName &&
                        userMe?.lastName[0] + userMe?.lastName[0]
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex-grow-1">
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-2">
                      <div className="text-gray-800 fs-2 fw-bolder me-1">
                        {userMe?.lastName} {userMe?.firstName}{" "}
                        {userMe?.middleName}
                      </div>
                    </div>
                    <div className="d-flex flex-column fw-bold fs-6 mb-4 pe-2">
                      <div className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                        <SVG
                          icon={<LocationIcon />}
                          className="svg-icon-4 me-1"
                        />
                        {userMe?.organization?.name}
                      </div>
                      <a
                        href={`mailto:${userMe?.organization?.email}`}
                        className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                      >
                        <SVG icon={<DotIcon />} className="svg-icon-4 me-1" />
                        {userMe?.organization?.email}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-5 mb-xl-10">
          <div className="card-header cursor-pointer">
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">{t("profileDetails")}</h3>
            </div>
            <button
              className="btn btn-primary align-self-center"
              onClick={handleOpen}
            >
              {t("editProfile")}
            </button>
            <Modal
              open={show}
              title={t("editProfile")}
              onCancel={handleClose}
              cancelText={t("cancel")}
              onOk={handleSubmit}
              okText={t("save")}
            >
              <div className="row row-cols-1">
                <div className="d-flex flex-center">
                  <div className="symbol symbol-125px w-125px h-125px symbol-fixed position-relative p-0">
                    <div className="image-input image-input-outline w-100 h-100">
                      <label
                        htmlFor="uploadFile"
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow position-absolute z-index-3"
                        data-kt-image-input-action="change"
                      >
                        <SVG icon={<EditIcon />} />
                      </label>
                      <input
                        type="file"
                        name="avatarId"
                        ref={ref}
                        className="d-none"
                        id="uploadFile"
                        onChange={handleUpload}
                      />
                      <img
                        className="avatar-img"
                        src={
                          form.avatarUrl
                            ? `${instance.defaults.baseURL}/api/v1/file${form?.avatarUrl}`
                            : user
                        }
                        alt={
                          userMe?.firstName &&
                          userMe?.lastName &&
                          userMe?.lastName[0] + userMe?.lastName[0]
                        }
                      />
                    </div>
                  </div>
                  {upload ? <Spin className="d-flex ms-2" size="small" /> : ""}
                </div>
                {userMe.role === "CLIENT" ? "" : (
                  <div className="col">
                    <InputGroup
                      label={t("email")}
                      type="email"
                      name="email"
                      required
                      value={form.email}
                      onChange={changeHandler}
                    />
                  </div>
                )}
                <div className="col">
                  <InputGroup
                    label={t("oldPassword")}
                    type="text"
                    name="oldPassword"
                    required
                    value={form.oldPassword}
                    onChange={changeHandler}
                  />
                </div>
                <div className="col">
                  <InputGroup
                    label={t("newPassword")}
                    type="text"
                    name="newPassword"
                    required
                    value={form.newPassword}
                    onChange={changeHandler}
                  />
                </div>
                <div className="col">
                  <InputGroup
                    label={t("repeatNewPassword")}
                    type="text"
                    name="repeatNewPassword"
                    required
                    value={form.repeatNewPassword}
                    onChange={changeHandler}
                  />
                </div>
              </div>
            </Modal>
          </div>
          <div className="card-body p-9">
            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">{t("fio")}</label>
              <div className="col-lg-8">
                <span className="fw-bolder fs-6 text-dark">
                  {userMe?.lastName} {userMe?.firstName} {userMe?.middleName}
                </span>
              </div>
            </div>

            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">
                {t("organization")}
              </label>
              <div className="col-lg-8 fv-row">
                <span className="fw-bolder fs-6">
                  {userMe?.organization?.name}
                </span>
              </div>
            </div>

            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">
                {t("phone")}
              </label>
              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 me-2">
                  {userMe?.phoneNumber}
                </span>
              </div>
            </div>

            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">
                {t("activity")}
              </label>
              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 me-2">
                  {userMe?.organization?.activity}
                </span>
              </div>
            </div>

            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">
                {t("email")}
              </label>
              <div className="col-lg-8">
                <a
                  href={`mailto:${userMe?.organization?.email}`}
                  className="fw-bolder fs-6 text-dark text-hover-primary"
                >
                  {userMe?.organization?.email}
                </a>
              </div>
            </div>

            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">
                {t("plan")}
              </label>
              <div className="col-lg-8 fv-row">
                <span className="fw-bolder fs-6">
                  {userMe?.organization?.tariffType}
                </span>
              </div>
            </div>

            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">
                {t("requestsCount")}
              </label>
              <div className="col-lg-8 fv-row">
                <span className="fw-bolder fs-6">
                  {attempt?.attemptCount}
                </span>
              </div>
            </div>

            <div className="row mb-7">
              <label className="col-lg-4 fw-bold text-muted">
                {t("expiredDate")}
              </label>
              <div className="col-lg-8 fv-row">
                <span className="fw-bolder fs-6">
                  {attempt?.expireDate}
                </span>
              </div>
            </div>

            <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
              <span className="svg-icon svg-icon-2tx svg-icon-success me-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <circle
                    fill="#000000"
                    opacity="0.3"
                    cx="12"
                    cy="12"
                    r="10"
                  ></circle>
                  <rect
                    fill="#000000"
                    x="11"
                    y="7"
                    width="2"
                    height="8"
                    rx="1"
                  ></rect>
                  <rect
                    fill="#000000"
                    x="11"
                    y="16"
                    width="2"
                    height="2"
                    rx="1"
                  ></rect>
                </svg>
              </span>
              <div className="d-flex flex-stack flex-grow-1">
                <div className="fw-bold">
                  <h4 className="text-gray-800 fw-bolder">{t("warning")}!</h4>
                  <div className="fs-6 text-gray-600">{t("editPassInfo")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
