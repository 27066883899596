import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../core/Modal";
import {
  CheckedSupplierMibCreditorDocumentDTO,
  CheckedSupplierMibDebtorDocumentDTO, CheckedSupplierMinjustDTO
} from "../../../services/openapi";
import { ColumnGroupType, ColumnType } from "antd/lib/table";
import { PaginationProps } from "antd";
import CustomTable from "../../core/CustomTable";

type Props = {
  title: string
  data: CheckedSupplierMibDebtorDocumentDTO[] | CheckedSupplierMibCreditorDocumentDTO[] |
    CheckedSupplierMinjustDTO[];
  type?: 'minJust' | 'debtor' | 'creditor'
};
const OrganizationInfo: React.FC<Props> = ({title, data, type }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const onChangePagination: PaginationProps["onChange"] = (pageNumber) => {
    setPage(pageNumber - 1);
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPage(current);
    setSize(pageSize);
  };

  const columnsMib: (
    | ColumnGroupType<CheckedSupplierMibDebtorDocumentDTO>
    | ColumnType<CheckedSupplierMibDebtorDocumentDTO>
    )[] = [
    {
      title: "№",
      key: "id",
      width: "80px",
      className: "text-center gx-0",
      render: (row) => {
        let index = data.indexOf(row);
        return index + 1;
      },
    },
    {
      title: t("workNumber"),
      dataIndex: "workNumber",
      key: "workNumber",
    },
    {
      title: t("branchName"),
      dataIndex: "branchName",
      key: "branchName",
    },
    {
      title: t("residualBalance"),
      dataIndex: "residualBalance",
      key: "residualBalance",
    },
    {
      title: t("creditorName") ,
      dataIndex: "creditorName",
      key: "creditorName",
    },
  ];
  const columnsMibCreditor: (
    | ColumnGroupType<CheckedSupplierMibCreditorDocumentDTO>
    | ColumnType<CheckedSupplierMibCreditorDocumentDTO>
    )[] = [
    {
      title: "№",
      key: "id",
      width: "80px",
      className: "text-center gx-0",
      render: (row) => {
        let index = data.indexOf(row);
        return index + 1;
      },
    },
    {
      title: t("workNumber"),
      dataIndex: "workNumber",
      key: "workNumber",
    },
    {
      title: t("branchName"),
      dataIndex: "branchName",
      key: "branchName",
    },
    {
      title: t("residualBalance"),
      dataIndex: "residualBalance",
      key: "residualBalance",
    },
    {
      title:  t("debtorName"),
      dataIndex: "debtorName",
      key: "debtorName",
    },
  ];
  const columnsMinJust: (
    | ColumnGroupType<CheckedSupplierMinjustDTO>
    | ColumnType<CheckedSupplierMinjustDTO>
    )[] = [
    {
      title: "№",
      key: "id",
      width: "80px",
      className: "text-center gx-0",
      render: (row) => {
        let index = data.indexOf(row);
        return index + 1;
      },
    },
    {
      title: t("minjustRegisterNumber"),
      dataIndex: "registerNumber",
      key: "registerNumber",
    },
    {
      title: t("registrationDate"),
      dataIndex: "registrationDate",
      key: "registrationDate",
    },
    {
      title: t("expiryDate"),
      dataIndex: "expiryDate",
      key: "expiryDate",
    },
    {
      title: t("organizationName"),
      dataIndex: "organizationName",
      key: "organizationName",
    },
    {
      title: t("documentType"),
      dataIndex: "documentType",
      key: "documentType",
    },
    {
      title: t("activityTypes"),
      dataIndex: "activityTypes",
      key: "activityTypes",
    },
  ];
  return (
    <CustomModal
      title={t(`${title}`)}
      hiddenOK={true}
      btnTitle={t("show")}
      width={1400}
      btnClass="btn-sm btn-primary"
      children={
        <CustomTable
          columns={type === 'minJust' ? columnsMinJust : type === 'debtor' ? columnsMib : columnsMibCreditor}
          data={data}
          page={page}
          pageSize={size}
          loading={false}
          className="table-striped-rows"
          paginationShowChange={onShowSizeChange}
          paginationChange={onChangePagination}
          totalCount={data.length}
        />
      }
    />
  );
};

export default OrganizationInfo;
