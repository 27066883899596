/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResultOfSignInResponseDTO } from '../models/ApiResultOfSignInResponseDTO';
import type { SignInDTO } from '../models/SignInDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthControllerService {

    /**
     * Access va Refresh token olish uchun API
     * @param signInDto signInDTO
     * @returns ApiResultOfSignInResponseDTO OK
     * @throws ApiError
     */
    public static signInUsingPost(
signInDto: SignInDTO,
): CancelablePromise<ApiResultOfSignInResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/sign-in',
            body: signInDto,
        });
    }

}
