/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResultOfPageOfRoleDTO } from '../models/ApiResultOfPageOfRoleDTO';
import type { ApiResultOfRoleDTO } from '../models/ApiResultOfRoleDTO';
import type { ApiResultOfstring } from '../models/ApiResultOfstring';
import type { RoleCreateUpdateDTO } from '../models/RoleCreateUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleControllerService {

    /**
     * create
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static createUsingPost3(
dto: RoleCreateUpdateDTO,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/role',
            body: dto,
        });
    }

    /**
     * update
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static updateUsingPut3(
dto: RoleCreateUpdateDTO,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/role',
            body: dto,
        });
    }

    /**
     * attach
     * @param attach attach
     * @param permissionId permissionId
     * @param roleId roleId
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static attachUsingPost(
attach: boolean,
permissionId: number,
roleId: number,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/role/attach',
            query: {
                'attach': attach,
                'permissionId': permissionId,
                'roleId': roleId,
            },
        });
    }

    /**
     * getAll
     * @param page page
     * @param size size
     * @param id 
     * @param name 
     * @returns ApiResultOfPageOfRoleDTO OK
     * @throws ApiError
     */
    public static getAllUsingGet5(
page: number,
size: number,
id?: number,
name?: string,
): CancelablePromise<ApiResultOfPageOfRoleDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/role/get-all',
            query: {
                'id': id,
                'name': name,
                'page': page,
                'size': size,
            },
        });
    }

    /**
     * get
     * @param id id
     * @returns ApiResultOfRoleDTO OK
     * @throws ApiError
     */
    public static getUsingGet4(
id: number,
): CancelablePromise<ApiResultOfRoleDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/role/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * delete
     * @param id id
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static deleteUsingDelete4(
id: number,
): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/role/{id}',
            path: {
                'id': id,
            },
        });
    }

}
