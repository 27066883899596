import React from "react";
import Toolbar from "../../components/Toolbar";
import { useTranslation } from "react-i18next";
import { FreeServices } from "../../components/Tables/CheckSupplier/freeServices";

const Services = () => {
  const { t } = useTranslation();
  return (
    <>
      <Toolbar title={t("servicesTitle")} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row">
              {FreeServices.map((el, i) => {
                return (
                  <div
                    key={i}
                    className="col-12 col-md-3 col-lg-4 col-xl-3 mb-5"
                  >
                    <a
                      href={el.link}
                      target="_blank"
                      rel="noreferrer"
                      className="card h-65px justify-content-center
                                            text-center text-dark fw-bold  p-4"
                      dangerouslySetInnerHTML={{ __html: el.title }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
