import React from 'react';
import {UnscrupulousPerformers} from "../../components";

const Unscrupulous = () => {
  return (
   <UnscrupulousPerformers/>
  );
};

export default Unscrupulous;
