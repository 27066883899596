/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserCreateUpdateDTO = {
    avatarId?: number;
    firstName?: string;
    id?: number;
    lastName?: string;
    middleName?: string;
    organizationId?: number;
    password?: string;
    phoneNumber?: string;
    roleId?: number;
    status?: UserCreateUpdateDTO.status;
};

export namespace UserCreateUpdateDTO {

    export enum status {
        ACTIVE = 'ACTIVE',
        DELETED = 'DELETED',
        BLOCKED = 'BLOCKED',
    }


}
