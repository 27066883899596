import {IFilterMessage} from "../../models";
import {MessageControllerService, MessageCreateUpdateDTO} from "../../services/openapi";
import {toast} from "react-toastify";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const getAllMessages = createAsyncThunk(
    "message/get-all",
    async (args: IFilterMessage) => {
        try {
            const response = await MessageControllerService.getAllUsingGet1(
                (args.page = args.page || 0),
                (args.size = args.size || 25)
            );
            return response.data;
        } catch (error: any) {
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
);

export const createMessage = createAsyncThunk(
    "message/create",
    async (data: MessageCreateUpdateDTO) => {
        try {
            return await MessageControllerService.createUsingPost(data);
        } catch (error: any) {
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
);

export const getOneMessage = createAsyncThunk(
    "message/get-one",
    async (id: number) => {
        try {
            const response = await MessageControllerService.getUsingGet1(id);
            console.log(response)
            return response.data;
        } catch (error: any) {
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
);

export const updateMessage = createAsyncThunk(
    "message/update",
    async (data: MessageCreateUpdateDTO) => {
        try {
            return await MessageControllerService.updateUsingPut(data);
        } catch (error: any) {
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
);

export const deleteMessage = createAsyncThunk(
    "message/delete",
    async (id: number) => {
        try {
            return await MessageControllerService.deleteUsingDelete(id);
        } catch (error: any) {
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
);
