import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getAllCheckedSuppliers,
  getOneCheckedSuppliers,
  updateApplication,
} from "../../../redux/services/supplierService";
import { DeleteIcon, EditIcon } from "../../Icons";
import { SVG } from "../../core/SVG";
import {
  DatePicker,
  DatePickerProps,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Spin,
  Tabs,
} from "antd";
import InputGroup from "../../core/InputGroup";
import {
  addAffiliation,
  addCourtMonitoring,
  addDirObject,
  addFounder,
  addOrganizationMibCreditorDocuments,
  addOrganizationMibDebtorDocuments,
  addOrganizationMinjusts,
  addOrgObject,
  clearSupplier,
  removeAffiliation,
  removeCourtMonitoring,
  removeDirectorNibFile,
  removeDirObject,
  removeFounder,
  removeJustFile,
  removeNibFile,
  removeOrganizationMibCreditorDocuments,
  removeOrganizationMibDebtorDocuments,
  removeOrganizationMinjusts,
  removeOrgObject,
  removeSudFile,
  removeTransFile,
  setAffiliation,
  setCourtMonitoring,
  setDirectorMibFiles,
  setDirObject,
  setFounder,
  setJustFiles,
  setMibFiles, setOrganizationMibCreditorDocuments, setOrganizationMibDebtorDocuments, setOrganizationMinjusts,
  setOrgObject,
  setSudFiles,
  setSupplier,
  setTransFiles,
  setUploadDirectorMibFile,
  setUploadJustFile,
  setUploadMibFile,
  setUploadSudFile,
  setUploadTransFile,
} from "../../../redux/slices/supplier";
import moment from "moment";
import { CheckedSupplierDto } from "../../../services/openapi";
import { fieldCheck } from "../../../helpers/fieldCheck";
import { toast } from "react-toastify";
import { getCurrencyType } from "../../../redux/services/dictionaryService";
import instance from "../../../axios";
import Input from "../../core/Input";

type Props = {
  id?: any;
};
const CreateUpdateSupplier: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [upload, setUpload] = useState<boolean>(false);
  const [uploadMib, setUploadMib] = useState<boolean>(false);
  const [uploadDirectorMib, setUploadDirectorMib] = useState<boolean>(false);
  const [uploadJust, setUploadJust] = useState<boolean>(false);
  const [uploadTrans, setUploadTrans] = useState<boolean>(false);
  const ref = React.createRef<HTMLInputElement>();
  const mibRef = React.createRef<HTMLInputElement>();
  const directorMibRef = React.createRef<HTMLInputElement>();
  const justRef = React.createRef<HTMLInputElement>();
  const transRef = React.createRef<HTMLInputElement>();

  const dispatch = useAppDispatch();

  const {
    application,
    supplier,
    sudFiles,
    mibFiles,
    directorMibFiles,
    minjustFiles,
    mintransFiles,
    loading,
  } = useAppSelector((state) => state.supplier);

  const handleOpen = () => {
    setOpen(true);
    dispatch(getCurrencyType());
    if (id) {
      dispatch(getOneCheckedSuppliers(id));
    }
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(clearSupplier());
  };

  const changeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    key?: string,
    index?: number,
    fileKey?: string
  ) => {
    let url = "https://app.kontragent.uz/api/v1";
    let { name, value } = event.target;
    if (
      name === "oked" ||
      name === "mfo" ||
      name === "directorTin" ||
      name === "directorPinfl" ||
      name === "tin"
    ) {
      if (value.length > event.target.maxLength) {
        event.target.value = event.target.value.slice(
          0,
          event.target.maxLength
        );
      }
      if (name === "oked" && value.length === 5) {
        instance
          .get(`${url}/remote/oked-info/${value}`)
          .then((res) => {
            dispatch(
              setSupplier({
                name: "activity",
                value: res.data?.data?.name,
              })
            );
          })
          .catch((e: any) => {
            toast(e.message, {
              type: "error",
            });
          });
      }
      if (name === "mfo" && value.length === 5) {
        instance
          .get(`${url}/remote/bank-info/${value}`)
          .then((res) => {
            dispatch(
              setSupplier({
                name: "bank",
                value: res.data?.data?.bankName,
              })
            );
          })
          .catch((e: any) => {
            toast(e.message, {
              type: "error",
            });
          });
      }
    }
    if (key === "file") {
      if (fileKey === "sud") {
        setUpload(true);
      }
      if (fileKey === "mib") {
        setUploadMib(true);
      }
      if (fileKey === "directorMib") {
        setUploadDirectorMib(true);
      }
      if (fileKey === "just") {
        setUploadJust(true);
      }
      if (fileKey === "trans") {
        setUploadTrans(true);
      }
      if (!event.target.files) return;
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      instance
        .post("api/v1/file", formData)
        .then((res: any) => {
          if (fileKey === "sud") {
            if (ref.current) {
              ref.current.value = "";
            }
            setUpload(false);
            dispatch(setUploadSudFile(res?.data?.data?.id));
            dispatch(setSudFiles(res?.data));
          }
          if (fileKey === "mib") {
            if (mibRef.current) {
              mibRef.current.value = "";
            }
            setUploadMib(false);
            dispatch(setUploadMibFile(res?.data?.data?.id));
            dispatch(setMibFiles(res?.data));
          }
          if (fileKey === "directorMib") {
            if (directorMibRef.current) {
              directorMibRef.current.value = "";
            }
            setUploadDirectorMib(false);
            dispatch(setUploadDirectorMibFile(res?.data?.data?.id));
            dispatch(setDirectorMibFiles(res?.data));
            console.log("dir mib file", res.data);
          }
          if (fileKey === "just") {
            if (justRef.current) {
              justRef.current.value = "";
            }
            setUploadJust(false);
            dispatch(setUploadJustFile(res?.data?.data?.id));
            dispatch(setJustFiles(res?.data));
          }
          if (fileKey === "trans") {
            if (transRef.current) {
              transRef.current.value = "";
            }
            setUploadTrans(false);
            dispatch(setUploadTransFile(res?.data?.data?.id));
            dispatch(setTransFiles(res?.data));
          }
        })
        .catch((err: any) => {
          console.log(err);
          setUpload(false);
        });
    }
    if (key === "founder") {
      dispatch(
        setFounder({
          index,
          name,
          value,
        })
      );
    } else if (key === "dir") {
      dispatch(
        setDirObject({
          index,
          name,
          value,
        })
      );
    } else if (key === "org") {
      dispatch(
        setOrgObject({
          index,
          name,
          value,
        })
      );
    } else if (key === "affiliations") {
      dispatch(
        setAffiliation({
          index,
          name,
          value,
        })
      );
    } else if (key === "monitoring") {
      dispatch(
        setCourtMonitoring({
          index,
          name,
          value,
        })
      );
    } else if (key === "organizationMibDebtorDocuments") {
      dispatch(
        setOrganizationMibDebtorDocuments({
          index,
          name,
          value,
        })
      );
    } else if (key === "organizationMibCreditorDocuments") {
      dispatch(
        setOrganizationMibCreditorDocuments({
          index,
          name,
          value,
        })
      );
    } else if (key === "organizationMinjusts") {
      dispatch(
        setOrganizationMinjusts({
          index,
          name,
          value,
        })
      );
    } else {
      dispatch(
        setSupplier({
          name: event.target.name,
          value: event.target.value,
        })
      );
    }
  };

  const onChangeDatePicker: DatePickerProps["onChange"] = (_, dateString) => {
    dispatch(
      setSupplier({
        name: "registerDate",
        value: dateString,
      })
    );
  };
  const onChangeDatePickerNds: DatePickerProps["onChange"] = (
    _,
    dateString
  ) => {
    dispatch(
      setSupplier({
        name: "ndsRegDate",
        value: dateString,
      })
    );
  };

  // const onChangeDatePickerEndDate: DatePickerProps["onChange"] = (_, dateString) => {
  //   dispatch(
  //     setSupplier({
  //       name: 'endDateActivity',
  //       value: dateString,
  //     })
  //   );
  // };
  // const onChangeDatePickerDateOfTemporary: DatePickerProps["onChange"] = (_, dateString) => {
  //   dispatch(
  //     setSupplier({
  //       name: 'dateOfTemporarySuspensionOfActivity',
  //       value: dateString,
  //     })
  //   );
  // };

  const options = [
    {
      label: t("yes"),
      value: true,
    },
    {
      label: t("no"),
      value: false,
    },
    // {
    //   label: t('unknown'),
    //   value: null
    // }
  ];

  const onChangeRadio = (e: RadioChangeEvent, key?: string, index?: number) => {
    const { name, value } = e.target;
    if (key === "dir") {
      dispatch(
        setDirObject({
          index,
          name,
          value,
        })
      );
    } else if (key === "org") {
      dispatch(
        setOrgObject({
          index,
          name,
          value,
        })
      );
    } else {
      dispatch(
        setSupplier({
          name,
          value,
        })
      );
    }
  };

  const changeSelectHandler = (event?: any, name?: string) => {
    dispatch(
      setSupplier({
        name,
        value: event,
      })
    );
  };

  const addItems = (key: string) => {
    if (key === "founder") {
      dispatch(addFounder());
    }
    if (key === "dir") {
      dispatch(addDirObject());
    }
    if (key === "org") {
      dispatch(addOrgObject());
    }
    if (key === "affiliations") {
      dispatch(addAffiliation());
    }
    if (key === "monitoring") {
      dispatch(addCourtMonitoring());
    }
    if (key === "organizationMibDebtorDocuments") {
      dispatch(addOrganizationMibDebtorDocuments());
    }
    if (key === "organizationMibCreditorDocuments") {
      dispatch(addOrganizationMibCreditorDocuments());
    }
    if (key === "organizationMinjusts") {
      dispatch(addOrganizationMinjusts());
    }
  };

  const removeItems = (index: number, key: string) => {
    if (key === "founder") {
      dispatch(removeFounder({ index }));
    }
    if (key === "dir") {
      dispatch(removeDirObject({ index }));
    }
    if (key === "org") {
      dispatch(removeOrgObject({ index }));
    }
    if (key === "affiliations") {
      dispatch(removeAffiliation({ index }));
    }
    if (key === "monitoring") {
      dispatch(removeCourtMonitoring({ index }));
    }
    if (key === "organizationMibDebtorDocuments") {
      dispatch(removeOrganizationMibDebtorDocuments({index}));
    }
    if (key === "organizationMibCreditorDocuments") {
      dispatch(removeOrganizationMibCreditorDocuments({index}));
    }
    if (key === "organizationMinjusts") {
      dispatch(removeOrganizationMinjusts({index}));
    }
  };
  const handleSubmit = async () => {
    let data = { ...(application ?? {}) };
    delete (data as CheckedSupplierDto).checkedUser;
    delete (data as CheckedSupplierDto).checkedOrganization;
    delete (data as CheckedSupplierDto).status;
    delete (data as CheckedSupplierDto).statusCode;
    delete (data as CheckedSupplierDto).reportFile;
    delete (data as CheckedSupplierDto).qrCodeImg;
    delete (data as CheckedSupplierDto).time;
    data = {
      ...data,
      affiliations: application.affiliations!.filter((item) => {
        return item.tin!.length > 0 || item.organizationName!.length > 0
          ? fieldCheck(item)
          : null;
      }),
      directorCadasters: application.directorCadasters!.filter((item) => {
        return item.number!.length > 0 && item.area!.length > 0
          ? fieldCheck(item)
          : null;
      }),
      founders: application.founders!.filter((item) => {
        return item.name!.length > 0 || item.percent!.length > 0
          ? fieldCheck(item)
          : null;
      }),
      organizationCadasters: application.organizationCadasters!.filter(
        (item) => {
          return item.number!.length > 0 && item.area!.length > 0
            ? fieldCheck(item)
            : null;
        }
      ),
      activityStatus: application?.activityStatus?.toString(),
      lastCheckGNK: application?.lastCheckGNK?.toString()
    };
    let res: any = await dispatch(updateApplication(data));
    if (res.payload) {
      if (res.payload?.success) {
        toast(res.payload?.message, {
          type: "success",
        });
        handleClose();
        dispatch(
          getAllCheckedSuppliers({
            page: 0,
            size: 25,
          })
        );
      }
    }
  };

  const deleteFile = (id: number, key: string) => {
    if (key === "sud") {
      dispatch(removeSudFile({ id }));
    }
    if (key === "mib") {
      dispatch(removeNibFile({ id }));
    }
    if (key === "directorMib") {
      dispatch(removeDirectorNibFile({ id }));
    }
    if (key === "just") {
      dispatch(removeJustFile({ id }));
    }
    if (key === "trans") {
      dispatch(removeTransFile({ id }));
    }
  };
  const downloadFile = async (url?: string, name?: string) => {
    try {
      let res = await instance.get(`/api/v1/file${url}`, {
        responseType: "blob",
      });
      if (res.data) {
        let blob = new Blob([res.data], { type: "application/pdf" });
        let url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.target = "_blank";
        a.href = url;
        a.download = `${name}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    } catch (err: any) {
      toast(err.message, {
        type: "error",
      });
    }
  };

  // const currencyTypes = [
  //   {
  //     name: "UZS",
  //     code: "UZS",
  //   },
  //   {
  //     name: "USD",
  //     code: "USD",
  //   },
  //   {
  //     name: "EUR",
  //     code: "EUR",
  //   },
  //   {
  //     name: "RUB",
  //     code: "RUB",
  //   },
  // ];

  const ndsStatus = [
    {
      value: "Активный",
    },
    {
      value: "Не активный",
    },
    {
      value: "Ликвидирован",
    },
  ];

  const orgStatus = [
    {
      value: "Действующий",
    },
    {
      value: "Бездействующий",
    },
    {
      value: "Ликвидирован",
    },
    {
      value: "Признаны банкротом",
    },
  ];

  const taxTypes = [
    {
      value: "Нет",
    },
    {
      value: "Другие органы",
    },
    {
      value: "Аудит по добровольной ликвидации",
    },
    {
      value: "Выездная налоговая проверка",
    },
    {
      value: "Дополнительная (повторная) ревизия по уголовному делу",
    },
    {
      value: "Камеральная налоговая проверка",
    },
    {
      value: "Налоговый аудит",
    },
    {
      value: "Проверка согласно запросам других контролирующих органов",
    },
    {
      value: "Ревизия по уголовному делу",
    },
  ];

  return (
    <>
      <button
        className={`btn ${
          id
            ? "btn-icon btn-bg-light btn-active-color-primary me-1 w-20px h-20px"
            : "btn-sm btn-primary"
        }`}
        onClick={handleOpen}
      >
        {id ? <SVG icon={<EditIcon />} className="svg-icon-2" /> : t("create")}
      </button>
      <Modal
        title={
          <div className="d-flex align-items-center">
            {t(`${id ? "updateCheckedSupplier" : "createCheckedSupplier"}`)}
            {loading ? <Spin className="d-flex ms-2" size="small" /> : ""}
          </div>
        }
        open={open}
        onCancel={handleClose}
        cancelText={t("cancel")}
        onOk={handleSubmit}
        okText={t("save")}
        width={1400}
      >
        <Tabs
          defaultActiveKey="1"
          className="min-h-400px"
          items={[
            {
              label: t("commonInfo"),
              key: "common",
              children: (
                <>
                  <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-3 row-cols-lg-3">
                    <div className="col">
                      <InputGroup
                        label={t("tin")}
                        type="text"
                        name="inn"
                        required
                        value={supplier?.inn}
                        disabled
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col">
                      <InputGroup
                        label={t("orgName")}
                        type="text"
                        name="organizationName"
                        required
                        value={application.organizationName}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col">
                      <div className="row row-cols-2">
                        <div className="col">
                          <div className="fv-row mb-1">
                            <label className="fs-6 fw-semibold form-label mt-3">
                              <span className="required">
                                {t("registerDate")}
                              </span>
                            </label>
                            <DatePicker
                              onChange={onChangeDatePicker}
                              name="registerDate"
                              className="form-control form-control-solid h-40px"
                              format="DD.MM.YYYY"
                              placeholder={t("registerDate")}
                              value={
                                application.registerDate !== null
                                  ? moment(
                                    application?.registerDate,
                                    "DD.MM.YYYY"
                                  )
                                  : null
                              }
                            />
                          </div>
                        </div>
                        <div className="col">
                          <InputGroup
                            label={t("registerNumber")}
                            type="text"
                            name="registerNumber"
                            required
                            value={application.registerNumber}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <InputGroup
                        label={t("legalAddress")}
                        type="text"
                        name="address"
                        required
                        value={application.address}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col">
                      <InputGroup
                        label={t("orgEmail")}
                        type="text"
                        name="email"
                        required
                        value={application.email}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col">
                      <InputGroup
                        label={t("opf")}
                        type="text"
                        name="opf"
                        required
                        value={application.opf}
                        onChange={changeHandler}
                      />
                    </div>
                    {/*<div className="col">*/}
                    {/*  <InputGroup*/}
                    {/*    label={t('typeOfOwnership')}*/}
                    {/*    type="text"*/}
                    {/*    name="typeOfOwnership"*/}
                    {/*    required*/}
                    {/*    value={application.typeOfOwnership}*/}
                    {/*    onChange={changeHandler}*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className="col">
                      <InputGroup
                        label={t("oked")}
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        maxLength={5}
                        name="oked"
                        required
                        value={application.oked}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col">
                      <InputGroup
                        label={t("activity")}
                        type="text"
                        name="activity"
                        required
                        value={application.activity}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col">
                      <InputGroup
                        label={t("soogu")}
                        type="text"
                        name="soogu"
                        required
                        value={application.soogu}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col">
                      <div className="fv-row d-flex flex-column mb-1">
                        <label className="fs-6 fw-semibold form-label mt-3">
                          <span className="required">
                            {t("budgetOrganization")}
                          </span>
                        </label>
                        <Radio.Group
                          onChange={onChangeRadio}
                          name="isBudget"
                          value={application.isBudget}
                          className="mt-2"
                          buttonStyle="solid"
                        >
                          {options.map((val) => {
                            return (
                              <Radio.Button key={val.label} value={val.value}>
                                {val.label}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="col">
                      <InputGroup
                        label={t("ustavCapital")}
                        type="text"
                        name="ustavCapital"
                        required
                        value={application.ustavCapital}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col">
                      <InputGroup
                        label={t("taxDebt")}
                        type="number"
                        name="taxDebt"
                        required
                        value={application.taxDebt}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col">
                      <InputGroup
                        label={t("account")}
                        type="text"
                        name="account"
                        required
                        value={application.account}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col">
                      <InputGroup
                        label={t("mfoBank")}
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        maxLength={5}
                        name="mfo"
                        required
                        value={application.mfo}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col">
                      <InputGroup
                        label={t("bank")}
                        type="text"
                        name="bank"
                        required
                        value={application.bank}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col">
                      <label className="fs-6 fw-semibold form-label mt-3">
                        <span className="required">{t("activityStatus")}</span>
                      </label>
                      <Select
                        placeholder={t("choose")}
                        className="w-100 form-control form-select-solid p-0"
                        allowClear
                        value={application.activityStatus}
                        showSearch
                        mode="tags"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(e) =>
                          changeSelectHandler(e, "activityStatus")
                        }
                        options={orgStatus?.map((el) => {
                          return {
                            value: el.value,
                            label: el.value,
                          };
                        })}
                      />
                    </div>
                    <div className="col">
                      <div className="fv-row d-flex flex-column mb-1">
                        <label className="fs-6 fw-semibold form-label mt-3">
                          <span className="required">{t("largeTaxPayer")}</span>
                        </label>
                        <Radio.Group
                          onChange={onChangeRadio}
                          name="largeTaxPayer"
                          value={application.largeTaxPayer}
                          className="mt-2"
                          buttonStyle="solid"
                        >
                          {options.map((val) => {
                            return (
                              <Radio.Button key={val.label} value={val.value}>
                                {val.label}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                </>
              ),
            },
            // {
            //   label: t('chp'),
            //   key: 'chp',
            //   children: (
            //     <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-2 row-cols-lg-2">
            //       <div className="col">
            //         <div className="row row-cols-2">
            //           <div className="col">
            //             <div className="fv-row mb-1">
            //               <label className="fs-6 fw-semibold form-label mt-3">
            //                 <span className="required">{t("endDateActivity")}</span>
            //               </label>
            //               <DatePicker
            //                 onChange={onChangeDatePickerEndDate}
            //                 name="endDateActivity"
            //                 className="form-control form-control-solid h-40px"
            //                 format="DD.MM.YYYY"
            //                 placeholder={t("endDateActivity")}
            //                 value={
            //                   application.endDateActivity !== null
            //                     ? moment(application?.endDateActivity, "DD.MM.YYYY")
            //                     : null
            //                 }
            //               />
            //             </div>
            //           </div>
            //           <div className="col">
            //             <InputGroup
            //               label={t('reasonOfTermination')}
            //               type="text"
            //               name="reasonOfTermination"
            //               required
            //               value={application.reasonOfTermination}
            //               onChange={changeHandler}
            //             />
            //           </div>
            //         </div>
            //       </div>
            //       <div className="col">
            //         <div className="row row-cols-2">
            //           <div className="col">
            //             <div className="fv-row mb-1">
            //               <label className="fs-6 fw-semibold form-label mt-3">
            //                 <span className="required">{t("dateOfTemporarySuspensionOfActivity")}</span>
            //               </label>
            //               <DatePicker
            //                 onChange={onChangeDatePickerDateOfTemporary}
            //                 name="dateOfTemporarySuspensionOfActivity"
            //                 className="form-control form-control-solid h-40px"
            //                 format="DD.MM.YYYY"
            //                 placeholder={t("dateOfTemporarySuspensionOfActivity")}
            //                 value={
            //                   application.dateOfTemporarySuspensionOfActivity !== null
            //                     ? moment(application?.dateOfTemporarySuspensionOfActivity, "DD.MM.YYYY")
            //                     : null
            //                 }
            //               />
            //             </div>
            //           </div>
            //           <div className="col">
            //             <InputGroup
            //               label={t('reasonOfTemporarySuspensionOfActivity')}
            //               type="text"
            //               name="reasonOfTemporarySuspensionOfActivity"
            //               required
            //               value={application.reasonOfTemporarySuspensionOfActivity}
            //               onChange={changeHandler}
            //             />
            //           </div>
            //         </div>
            //       </div>
            //       <div className="col">
            //         <div className="row row-cols-2">
            //           <div className="col">
            //             <InputGroup
            //               label={t('termOfActivity')}
            //               type="text"
            //               name="termOfActivity"
            //               required
            //               value={application.termOfActivity}
            //               onChange={changeHandler}
            //             />
            //           </div>
            //         </div>
            //       </div>
            //     </div>
            //   )
            // },
            {
              label: t("payerNds"),
              key: "payerNds",
              children: (
                <>
                  <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-3 row-cols-lg-3">
                    <div className="col">
                      <div className="fv-row d-flex flex-column mb-1">
                        <label className="fs-6 fw-semibold form-label mt-3">
                          <span className="required">{t("isNdsPayer")}</span>
                        </label>
                        <Radio.Group
                          onChange={onChangeRadio}
                          name="isNdsPayer"
                          value={application.isNdsPayer}
                          className="mt-2"
                          buttonStyle="solid"
                        >
                          {options.map((val) => {
                            return (
                              <Radio.Button key={val.label} value={val.value}>
                                {val.label}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </div>
                    {application.isNdsPayer ? (
                      <>
                        <div className="col">
                          <label className="fs-6 fw-semibold form-label mt-3">
                            <span className="required">{t("ndsStatus")}</span>
                          </label>
                          <Select
                            placeholder={t("choose")}
                            className="w-100 form-control form-select-solid p-0"
                            allowClear
                            value={application.ndsStatus}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(e) =>
                              changeSelectHandler(e, "ndsStatus")
                            }
                            options={ndsStatus?.map((el) => {
                              return {
                                value: el.value,
                                label: el.value,
                              };
                            })}
                          />
                        </div>
                        <div className="col">
                          <div className="fv-row mb-1">
                            <label className="fs-6 fw-semibold form-label mt-3">
                              <span className="required">{t("date")}</span>
                            </label>
                            <DatePicker
                              onChange={onChangeDatePickerNds}
                              name="ndsRegDate"
                              className="form-control form-control-solid h-40px"
                              format="DD.MM.YYYY"
                              placeholder={t("date")}
                              value={
                                application.ndsRegDate !== null
                                  ? moment(
                                      application?.ndsRegDate,
                                      "DD.MM.YYYY"
                                    )
                                  : null
                              }
                            />
                          </div>
                        </div>
                        <div className="col">
                          <InputGroup
                            label={t("number")}
                            type="text"
                            name="ndsRegNumber"
                            required
                            value={application.ndsRegNumber}
                            onChange={changeHandler}
                          />
                        </div>
                        {/*<div className="col">*/}
                        {/*  <InputGroup*/}
                        {/*    label={t('taxGap')}*/}
                        {/*    type="text"*/}
                        {/*    name="taxGap"*/}
                        {/*    required*/}
                        {/*    value={application.taxGap}*/}
                        {/*    onChange={changeHandler}*/}
                        {/*  />*/}
                        {/*</div>*/}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ),
            },
            {
              label: t("founderInfo"),
              key: "founderInfo",
              children: (
                <>
                  {application.founders?.map((founder, i: number) => {
                    return (
                      <div
                        className="row row-cols-1 row-cols-sm-2 rol-cols-md-3"
                        key={i}
                      >
                        <div className="col">
                          <InputGroup
                            label={t("founderName")}
                            type="text"
                            name="name"
                            required
                            value={
                              application.founders &&
                              application.founders[i].name!
                            }
                            onChange={(e) => changeHandler(e, "founder", i)}
                          />
                        </div>
                        <div className="col d-flex align-items-end">
                          <InputGroup
                            label={t("tin")}
                            type="number"
                            name="inn"
                            pattern="[0-9]*"
                            groupClassName="me-4"
                            required
                            value={
                              application.founders &&
                              application.founders[i].inn!
                            }
                            onChange={(e) => changeHandler(e, "founder", i)}
                          />
                          <InputGroup
                            label={t("founderPercent")}
                            type="number"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            name="percent"
                            required
                            value={
                              application.founders &&
                              application.founders[i].percent!
                            }
                            onChange={(e) => changeHandler(e, "founder", i)}
                          />
                          <div className="mb-1">
                            {application.founders?.length !== 1 && (
                              <button
                                onClick={() => removeItems(i, "founder")}
                                className="btn btn-sm btn-danger ms-4"
                              >
                                -
                              </button>
                            )}
                            {application.founders!.length - 1 === i && (
                              <button
                                onClick={() => addItems("founder")}
                                className="btn btn-sm btn-primary ms-1"
                              >
                                +
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ),
            },
            {
              label: t("directorInfo"),
              key: "directorInfo",
              children: (
                <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-3">
                  <div className="col">
                    <InputGroup
                      label={t("directorName")}
                      type="text"
                      name="directorName"
                      required
                      value={application.directorName}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="col">
                    <div className="row row-cols-2">
                      <div className="col">
                        <InputGroup
                          label={t("directorTin")}
                          type="number"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          maxLength={9}
                          name="directorTin"
                          required
                          value={application.directorTin}
                          onChange={changeHandler}
                        />
                      </div>
                      <div className="col">
                        <InputGroup
                          label={t("directorPinfl")}
                          type="number"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          maxLength={14}
                          name="directorPinfl"
                          required
                          value={application.directorPinfl}
                          onChange={changeHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <InputGroup
                      label={t("directorPhone")}
                      type="text"
                      name="directorPhone"
                      required
                      value={application.directorPhone}
                      onChange={changeHandler}
                    />
                  </div>
                  {/*<div className="col">*/}
                  {/*  <InputGroup*/}
                  {/*    label={t("directorMibDebt")}*/}
                  {/*    type="number"*/}
                  {/*    name="directorMibDebt"*/}
                  {/*    required*/}
                  {/*    value={application.directorMibDebt}*/}
                  {/*    onChange={changeHandler}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  {/*<div className="col">*/}
                  {/*  <div className="fv-row d-flex flex-column mb-1">*/}
                  {/*    <label className="fs-6 fw-semibold form-label mt-3">*/}
                  {/*      <span className="required">{t('directorTravelRestriction')}</span>*/}
                  {/*    </label>*/}
                  {/*    <Radio.Group*/}
                  {/*      onChange={onChangeRadio}*/}
                  {/*      name="directorTravelRestriction"*/}
                  {/*      value={application.directorTravelRestriction}*/}
                  {/*      className="mt-2"*/}
                  {/*      buttonStyle="solid"*/}
                  {/*    >*/}
                  {/*      {options.map((val) => {*/}
                  {/*        return (*/}
                  {/*          <Radio.Button key={val.label} value={val.value}>*/}
                  {/*            {val.label}*/}
                  {/*          </Radio.Button>*/}
                  {/*        );*/}
                  {/*      })}*/}
                  {/*    </Radio.Group>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="col">
                    <InputGroup
                      label={t("accountantName")}
                      type="text"
                      name="accountantName"
                      required
                      value={application.accountantName}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="col">
                    <div className="d-flex align-items-end flex-wrap">
                      <div className="fv-row d-flex flex-column align-items-start">
                        <label className="fs-6 fw-semibold form-label mt-3">
                          <span className="required">{t("file")}</span>
                        </label>
                        <label
                          htmlFor="uploadDirectorMibFile"
                          className="btn btn-secondary w-200px fs-6 fw-semibold form-label mb-4 d-flex flex-column"
                        >
                          {t("chooseFile")}
                        </label>
                        {uploadDirectorMib ? (
                          <Spin className="d-flex ms-2" size="small" />
                        ) : (
                          ""
                        )}
                        <input
                          type="file"
                          name="file"
                          ref={directorMibRef}
                          className="d-none"
                          id="uploadDirectorMibFile"
                          onChange={(e) =>
                            changeHandler(e, "file", undefined, "directorMib")
                          }
                        />
                      </div>
                    </div>
                    <div className="w-100 row row-cols-1 row-cols-sm-2 row-cols-lg-3 mx-0">
                      {directorMibFiles &&
                        directorMibFiles.map((file: any, index: number) => {
                          return (
                            <div className="col py-3 ps-0 mb-3" key={index}>
                              <div className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">
                                <a
                                  href="#/"
                                  onClick={() =>
                                    downloadFile(file?.url, file?.name)
                                  }
                                  className="text-truncate"
                                >
                                  {file?.name}
                                </a>
                                <button
                                  className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2"
                                  onClick={() => deleteFile(file?.id, "mib")}
                                >
                                  <SVG
                                    icon={<DeleteIcon />}
                                    className="svg-icon-2"
                                  />
                                </button>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              ),
            },
            {
              label: t("directorObject"),
              key: "directorObject",
              children: (
                <>
                  {application.directorCadasters?.map((element, i: number) => {
                    return (
                      <div
                        className="row row-cols-1 row-cols-sm-4 rol-cols-md-4"
                        key={i}
                      >
                        <div className="col">
                          <InputGroup
                            label={t("cadastreNumber")}
                            type="text"
                            name="number"
                            required
                            value={
                              application.directorCadasters &&
                              application.directorCadasters[i].number!
                            }
                            onChange={(e) => changeHandler(e, "dir", i)}
                          />
                        </div>
                        <div className="col">
                          <InputGroup
                            label={t("area")}
                            type="text"
                            name="area"
                            required
                            value={
                              application.directorCadasters &&
                              application.directorCadasters[i].area!
                            }
                            onChange={(e) => changeHandler(e, "dir", i)}
                          />
                        </div>
                        <div className="col">
                          <div className="fv-row  d-flex flex-column mb-1">
                            <label className="fs-6 fw-semibold form-label mt-3">
                              <span className="required">{t("ban")}</span>
                            </label>
                            <Radio.Group
                              onChange={(e) => onChangeRadio(e, "dir", i)}
                              name="ban"
                              value={
                                application.directorCadasters &&
                                application.directorCadasters[i].ban!
                              }
                              className="mt-2"
                              buttonStyle="solid"
                            >
                              {options.map((val) => {
                                return (
                                  <Radio.Button
                                    key={val.label}
                                    value={val.value}
                                  >
                                    {val.label}
                                  </Radio.Button>
                                );
                              })}
                            </Radio.Group>
                          </div>
                        </div>
                        <div className="col d-flex align-items-end">
                          <InputGroup
                            label={t("info")}
                            type="text"
                            name="info"
                            required
                            value={
                              application.directorCadasters &&
                              application.directorCadasters[i].info
                            }
                            onChange={(e) => changeHandler(e, "dir", i)}
                          />
                          <div className="mb-1">
                            {application.directorCadasters?.length !== 1 && (
                              <button
                                onClick={() => removeItems(i, "dir")}
                                className="btn btn-sm btn-danger ms-4"
                              >
                                -
                              </button>
                            )}
                            {application.directorCadasters!.length - 1 ===
                              i && (
                              <button
                                onClick={() => addItems("dir")}
                                className="btn btn-sm btn-primary ms-1"
                              >
                                +
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ),
            },
            {
              label: t("supplierObject"),
              key: "supplierObject",
              children: (
                <>
                  {application.organizationCadasters?.map(
                    (element, i: number) => {
                      return (
                        <div
                          className="row row-cols-1 row-cols-sm-4 rol-cols-md-4"
                          key={i}
                        >
                          <div className="col">
                            <InputGroup
                              label={t("cadastreNumber")}
                              type="text"
                              name="number"
                              required
                              value={
                                application.organizationCadasters &&
                                application.organizationCadasters[i].number!
                              }
                              onChange={(e) => changeHandler(e, "org", i)}
                            />
                          </div>
                          <div className="col">
                            <InputGroup
                              label={t("area")}
                              type="text"
                              name="area"
                              required
                              value={
                                application.organizationCadasters &&
                                application.organizationCadasters[i].area!
                              }
                              onChange={(e) => changeHandler(e, "org", i)}
                            />
                          </div>
                          <div className="col">
                            <div className="fv-row  d-flex flex-column mb-1">
                              <label className="fs-6 fw-semibold form-label mt-3">
                                <span className="required">{t("ban")}</span>
                              </label>
                              <Radio.Group
                                onChange={(e) => onChangeRadio(e, "org", i)}
                                name="ban"
                                value={
                                  application.organizationCadasters &&
                                  application.organizationCadasters[i].ban!
                                }
                                className="mt-2"
                                buttonStyle="solid"
                              >
                                {options.map((val) => {
                                  return (
                                    <Radio.Button
                                      key={val.label}
                                      value={val.value}
                                    >
                                      {val.label}
                                    </Radio.Button>
                                  );
                                })}
                              </Radio.Group>
                            </div>
                          </div>
                          <div className="col d-flex align-items-end">
                            <InputGroup
                              label={t("info")}
                              type="text"
                              name="info"
                              required
                              value={
                                application.organizationCadasters &&
                                application.organizationCadasters[i].info
                              }
                              onChange={(e) => changeHandler(e, "org", i)}
                            />
                            <div className="mb-1">
                              {application.organizationCadasters?.length !==
                                1 && (
                                <button
                                  onClick={() => removeItems(i, "org")}
                                  className="btn btn-sm btn-danger ms-4"
                                >
                                  -
                                </button>
                              )}
                              {application.organizationCadasters!.length - 1 ===
                                i && (
                                <button
                                  onClick={() => addItems("org")}
                                  className="btn btn-sm btn-primary ms-1"
                                >
                                  +
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </>
              ),
            },
            {
              label: t("affiliation"),
              key: "affiliation",
              children: (
                <>
                  {application.affiliations?.map((founder, i: number) => {
                    return (
                      <div
                        className="row row-cols-1 row-cols-sm-2 row-cols-md-3"
                        key={i}
                      >
                        <div className="col w-200px">
                          <InputGroup
                            label={t("tin")}
                            type="number"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            maxLength={9}
                            name="tin"
                            required
                            value={
                              application.affiliations &&
                              application.affiliations[i].tin!
                            }
                            onChange={(e) =>
                              changeHandler(e, "affiliations", i)
                            }
                          />
                        </div>
                        <div className="col d-flex align-items-end">
                          <InputGroup
                            label={t("orgName")}
                            type="text"
                            name="organizationName"
                            required
                            className="w-400px"
                            value={
                              application.affiliations &&
                              application.affiliations[i].organizationName!
                            }
                            onChange={(e) =>
                              changeHandler(e, "affiliations", i)
                            }
                          />
                          <div className="mb-1">
                            {application.affiliations?.length !== 1 && (
                              <button
                                onClick={() => removeItems(i, "affiliations")}
                                className="btn btn-sm btn-danger ms-4"
                              >
                                -
                              </button>
                            )}
                            {application.affiliations!.length - 1 === i && (
                              <button
                                onClick={() => addItems("affiliations")}
                                className="btn btn-sm btn-primary ms-1"
                              >
                                +
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ),
            },
            {
              label: t("courtMonitoring"),
              key: "courtMonitoring",
              children: (
                <>
                  <div className="d-flex align-items-end flex-wrap">
                    <div className="fv-row mb-1 d-flex align-items-center">
                      <label
                        htmlFor="uploadFile"
                        className="btn btn-secondary w-200px fs-6 fw-semibold form-label mt-3 mb-4 d-flex flex-column"
                      >
                        {t("chooseFile")}
                      </label>
                      {upload ? (
                        <Spin className="d-flex ms-2" size="small" />
                      ) : (
                        ""
                      )}
                      <input
                        type="file"
                        name="file"
                        ref={ref}
                        className="d-none"
                        id="uploadFile"
                        onChange={(e) =>
                          changeHandler(e, "file", undefined, "sud")
                        }
                      />
                    </div>
                    <button
                      onClick={() => addItems("monitoring")}
                      className="btn btn-sm btn-primary ms-1 mb-5"
                    >
                      {t("add")}
                    </button>
                  </div>
                  <div className="w-100 row row-cols-1 row-cols-sm-2 rol-cols-md-3 row-cols-lg-5 mx-0">
                    {sudFiles &&
                      sudFiles.map((file: any, index: number) => {
                        return (
                          <div className="col py-3 ps-0 mb-3" key={index}>
                            <div className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">
                              <a
                                href="#/"
                                onClick={() =>
                                  downloadFile(file?.url, file?.name)
                                }
                                className="text-truncate"
                              >
                                {file?.name}
                              </a>
                              <button
                                className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2"
                                onClick={() => deleteFile(file?.id, "sud")}
                              >
                                <SVG
                                  icon={<DeleteIcon />}
                                  className="svg-icon-2"
                                />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {application.sudMonitorings?.map((founder, i: number) => {
                    return (
                      <div className="row row-cols-sm-1" key={i}>
                        <div className="col min-w-150px mw-150px">
                          <InputGroup
                            label={t("registerDate")}
                            type="text"
                            name="reg_date"
                            required
                            value={
                              application.sudMonitorings &&
                              application.sudMonitorings[i].reg_date!
                            }
                            onChange={(e) => changeHandler(e, "monitoring", i)}
                          />
                        </div>
                        <div className="col min-w-150px mw-150px">
                          <InputGroup
                            label={t("casenumber")}
                            type="text"
                            name="casenumber"
                            required
                            value={
                              application.sudMonitorings &&
                              application.sudMonitorings[i].casenumber!
                            }
                            onChange={(e) => changeHandler(e, "monitoring", i)}
                          />
                        </div>
                        <div className="col min-w-175px mw-175px">
                          <InputGroup
                            label={t("hearingDate")}
                            type="text"
                            name="hearing_date"
                            required
                            value={
                              application.sudMonitorings &&
                              application.sudMonitorings[i].hearing_date!
                            }
                            onChange={(e) => changeHandler(e, "monitoring", i)}
                          />
                        </div>
                        <div className="col min-w-200px mw-200px">
                          <InputGroup
                            label={t("claimant")}
                            type="text"
                            name="claimant"
                            required
                            value={
                              application.sudMonitorings &&
                              application.sudMonitorings[i].claimant!
                            }
                            onChange={(e) => changeHandler(e, "monitoring", i)}
                          />
                        </div>
                        <div className="col min-w-200px mw-200px">
                          <InputGroup
                            label={t("defendant")}
                            type="text"
                            name="defendant"
                            required
                            value={
                              application.sudMonitorings &&
                              application.sudMonitorings[i].defendant!
                            }
                            onChange={(e) => changeHandler(e, "monitoring", i)}
                          />
                        </div>
                        <div className="col d-flex align-items-end min-w-300px mw-300px">
                          <div className="w-100">
                            <InputGroup
                              label={t("courtResult")}
                              type="text"
                              name="result"
                              required
                              value={
                                application.sudMonitorings &&
                                application.sudMonitorings[i].result!
                              }
                              onChange={(e) =>
                                changeHandler(e, "monitoring", i)
                              }
                            />
                          </div>
                          <div className="mb-1 d-flex">
                            <button
                              onClick={() => removeItems(i, "monitoring")}
                              className="btn btn-sm btn-danger ms-4"
                            >
                              -
                            </button>
                            {application.sudMonitorings!.length - 1 === i && (
                              <button
                                onClick={() => addItems("monitoring")}
                                className="btn btn-sm btn-primary ms-1"
                              >
                                +
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ),
            },
            {
              key: '9',
              label: 'БПИ / долг контрагента',
              children: (
                <>
                  <div className="fv-row d-flex flex-column mb-1">
                    {/*<label className="fs-6 fw-semibold form-label mt-3">*/}
                    {/*  <span className="required">{t("isOrganizationMibDebtor")}</span>*/}
                    {/*</label>*/}
                    <Radio.Group
                      onChange={onChangeRadio}
                      name="isOrganizationMibDebtor"
                      value={application.isOrganizationMibDebtor}
                      className="mt-2"
                      buttonStyle="solid"
                    >
                      {options.map((val) => {
                        return (
                          <Radio.Button key={val.label} value={val.value}>
                            {val.label}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  </div>
                  <div className="row row-cols-4">
                    <div className="col">
                      <InputGroup
                        label={t("organizationMibDebt")}
                        type="number"
                        name="organizationMibDebt"
                        required
                        value={application.organizationMibDebt}
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                  {application?.organizationMibDebtorDocuments?.length ? (
                    application.organizationMibDebtorDocuments.map((_, i) => {
                      return (
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
                          <div className="col">
                            <InputGroup
                              label="Номер исполнительного дела"
                              type="text"
                              name="workNumber"
                              value={
                                application?.organizationMibDebtorDocuments &&
                                application?.organizationMibDebtorDocuments[i]?.workNumber
                              }
                              onChange={(event) => changeHandler(event, 'organizationMibDebtorDocuments', i)}
                            />
                          </div>
                          <div className="col">
                            <InputGroup
                              label="Где находится в производстве"
                              type="text"
                              name="branchName"
                              value={
                                application?.organizationMibDebtorDocuments &&
                                application?.organizationMibDebtorDocuments[i]?.branchName
                              }
                              onChange={(event) => changeHandler(event, 'organizationMibDebtorDocuments', i)}
                            />
                          </div>
                          <div className="col w-[15%]">
                            <InputGroup
                              label="Сумма (сум)"
                              type="text"
                              name="residualBalance"
                              value={
                                application?.organizationMibDebtorDocuments &&
                                application?.organizationMibDebtorDocuments[i]?.residualBalance
                              }
                              onChange={(event) => changeHandler(event, 'organizationMibDebtorDocuments', i)}
                            />
                          </div>
                          <div className="col d-flex align-items-end w-[35%]">
                            <InputGroup
                              label="В пользу"
                              type="text"
                              name="creditorName"
                              groupClassName="w-full"
                              value={
                                application?.organizationMibDebtorDocuments &&
                                application?.organizationMibDebtorDocuments[i]?.creditorName
                              }
                              onChange={(event) => changeHandler(event, 'organizationMibDebtorDocuments', i)}
                            />
                            <div className="d-flex ml-1 mb-2">
                              {application.organizationMibDebtorDocuments?.length !== 1 && (
                                <button
                                  onClick={() => removeItems(i, 'organizationMibDebtorDocuments')}
                                  className="btn btn-danger btn-sm"
                                >
                                  -
                                </button>
                              )}
                              {application.organizationMibDebtorDocuments!.length - 1 === i && (
                                <button
                                  onClick={() => addItems('organizationMibDebtorDocuments')}
                                  className="btn btn-primary btn-sm ml-1"
                                >
                                  +
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <button
                      onClick={() => addItems('organizationMibDebtorDocuments')}
                      className="btn btn-sm btn-primary"
                    >
                      Добавить
                    </button>
                  )}
                </>
              ),
            },
            {
              key: '10',
              label: 'БПИ / должники',
              children: (
                <>
                  <div className="fv-row d-flex flex-column mb-1">
                    {/*<label className="fs-6 fw-semibold form-label mt-3">*/}
                    {/*  <span className="required">{t("isOrganizationMibCreditor")}</span>*/}
                    {/*</label>*/}
                    <Radio.Group
                      onChange={onChangeRadio}
                      name="isOrganizationMibCreditor"
                      value={application.isOrganizationMibCreditor}
                      className="mt-2"
                      buttonStyle="solid"
                    >
                      {options.map((val) => {
                        return (
                          <Radio.Button key={val.label} value={val.value}>
                            {val.label}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  </div>
                  <div className="row row-cols-4">
                    <div className="col">
                      <InputGroup
                        label={t("organizationMibCreditorAmount")}
                        type="number"
                        name="organizationMibCreditorAmount"
                        required
                        value={application.organizationMibCreditorAmount}
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                  {application?.organizationMibCreditorDocuments?.length ? (
                    application.organizationMibCreditorDocuments.map((_, i) => {
                      return (
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
                          <div className="col">
                            <InputGroup
                              label="Номер исполнительного дела"
                              type="text"
                              name="workNumber"
                              value={
                                application?.organizationMibCreditorDocuments &&
                                application?.organizationMibCreditorDocuments[i]?.workNumber
                              }
                              onChange={(event) => changeHandler(event, 'organizationMibCreditorDocuments', i)}
                            />
                          </div>
                          <div className="col">
                            <InputGroup
                              label="Где находится в производстве"
                              type="text"
                              name="branchName"
                              value={
                                application?.organizationMibCreditorDocuments &&
                                application?.organizationMibCreditorDocuments[i]?.branchName
                              }
                              onChange={(event) => changeHandler(event, 'organizationMibCreditorDocuments', i)}
                            />
                          </div>
                          <div className="col w-[15%]">
                            <InputGroup
                              label="Сумма (сум)"
                              type="text"
                              name="residualBalance"
                              value={
                                application?.organizationMibCreditorDocuments &&
                                application?.organizationMibCreditorDocuments[i]?.residualBalance
                              }
                              onChange={(event) => changeHandler(event, 'organizationMibCreditorDocuments', i)}
                            />
                          </div>
                          <div className="col d-flex align-items-end w-[35%]">
                            <InputGroup
                              label="Должник"
                              type="text"
                              groupClassName="w-full"
                              name="debtorName"
                              value={
                                application?.organizationMibCreditorDocuments &&
                                application?.organizationMibCreditorDocuments[i]?.debtorName
                              }
                              onChange={(event) => changeHandler(event, 'organizationMibCreditorDocuments', i)}
                            />
                            <div className="d-flex ml-1 mb-2">
                              {application.organizationMibCreditorDocuments?.length !== 1 && (
                                <button
                                  onClick={() => removeItems(i, 'organizationMibCreditorDocuments')}
                                  className="btn btn-danger btn-sm"
                                >
                                  -
                                </button>
                              )}
                              {application.organizationMibCreditorDocuments!.length - 1 === i && (
                                <button
                                  onClick={() => addItems('organizationMibCreditorDocuments')}
                                  className="btn btn-primary btn-sm ml-1"
                                >
                                  +
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <button
                      onClick={() => addItems('organizationMibCreditorDocuments')}
                      className="btn btn-sm btn-primary"
                    >
                      Добавить
                    </button>
                  )}
                </>
              ),
            },
            {
              key: '11',
              label: 'Минюст',
              children: (
                <>
                  <div className="fv-row d-flex flex-column mb-1">
                    <label className="fs-6 fw-semibold form-label mt-3">
                      <span className="required">{t("licenseOfMinyust")}</span>
                    </label>
                    <Radio.Group
                      onChange={onChangeRadio}
                      name="licenseOfMinyust"
                      value={application.licenseOfMinyust}
                      className="mt-2"
                      buttonStyle="solid"
                    >
                      {options.map((val) => {
                        return (
                          <Radio.Button key={val.label} value={val.value}>
                            {val.label}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  </div>
                  {application?.organizationMinjusts?.length ? (
                    application.organizationMinjusts.map((_, i) => {
                      return (
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-6 row-cols-lg-6">
                          <div className="col">
                            <InputGroup
                              label="Регистрационный номер"
                              type="text"
                              name="registerNumber"
                              value={application?.organizationMinjusts && application?.organizationMinjusts[i]?.registerNumber}
                              onChange={(event) => changeHandler(event, 'organizationMinjusts', i)}
                            />
                          </div>
                          <div className="col w-[10%]">
                            <InputGroup
                              label="Дата выдачи"
                              type="text"
                              name="registrationDate"
                              value={application?.organizationMinjusts && application?.organizationMinjusts[i]?.registrationDate}
                              onChange={(event) => changeHandler(event, 'organizationMinjusts', i)}
                            />
                          </div>
                          <div className="col w-[12%]">
                            <InputGroup
                              label="Срок окончания"
                              type="text"
                              name="expiryDate"
                              value={application?.organizationMinjusts && application?.organizationMinjusts[i]?.expiryDate}
                              onChange={(event) => changeHandler(event, 'organizationMinjusts', i)}
                            />
                          </div>
                          <div className="col w-[21%]">
                            <InputGroup
                              label="Орг, выдавшая документ"
                              type="text"
                              name="organizationName"
                              value={application?.organizationMinjusts && application?.organizationMinjusts[i]?.organizationName}
                              onChange={(event) => changeHandler(event, 'organizationMinjusts', i)}
                            />
                          </div>
                          <div className="col">
                            <InputGroup
                              label="Вид документа"
                              type="text"
                              name="documentType"
                              value={application?.organizationMinjusts && application?.organizationMinjusts[i]?.documentType}
                              onChange={(event) => changeHandler(event, 'organizationMinjusts', i)}
                            />
                          </div>
                          <div className="col d-flex align-items-end w-[23%]">
                            <InputGroup
                              label="Тип документа"
                              type="text"
                              groupClassName="w-full"
                              name="activityTypes"
                              value={application?.organizationMinjusts && application?.organizationMinjusts[i]?.activityTypes}
                              onChange={(event) => changeHandler(event, 'organizationMinjusts', i)}
                            />
                            <div className="d-flex ml-1 mb-2">
                              {application.organizationMinjusts?.length !== 1 && (
                                <button
                                  onClick={() => removeItems(i, 'organizationMinjusts')}
                                  className="btn btn-danger btn-sm"
                                >
                                  -
                                </button>
                              )}
                              {application.organizationMinjusts!.length - 1 === i && (
                                <button
                                  onClick={() => addItems('organizationMinjusts')}
                                  className="btn btn-primary btn-sm ml-1"
                                >
                                  +
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <button
                      onClick={() => addItems('organizationMinjusts')}
                      className="btn btn-sm btn-primary"
                    >
                      Добавить
                    </button>
                  )}
                </>
              ),
            },
            {
              label: t("additionalInfo"),
              key: "additionalInfo",
              children: (
                <>
                  <div className="row row-cols-1 row-cols-sm-2">
                    <div className="col">
                      <div className="row row-cols-2">
                        <div className="col">
                          <InputGroup
                            label={t("ratingGeneralConstruction")}
                            type="text"
                            name="ratingGeneralConstruction"
                            required
                            value={application.ratingGeneralConstruction}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="col">
                          <InputGroup
                            label={t("ratingReclamationAndIrrigation")}
                            type="text"
                            name="ratingReclamationAndIrrigation"
                            required
                            value={application.ratingReclamationAndIrrigation}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row row-cols-2">
                        <div className="col">
                          <InputGroup
                            label={t("ratingHighwaysAndBridges")}
                            type="text"
                            name="ratingHighwaysAndBridges"
                            required
                            value={application.ratingHighwaysAndBridges}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="col">
                          <InputGroup
                            label={t("ratingDesignOrganizations")}
                            type="text"
                            name="ratingDesignOrganizations"
                            required
                            value={application.ratingDesignOrganizations}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="fv-row d-flex flex-column mb-1">
                        <label className="fs-6 fw-semibold form-label mt-3">
                          <span className="required">
                            {t("licenseOfMinyust")}
                          </span>
                        </label>
                        <Radio.Group
                          onChange={onChangeRadio}
                          name="licenseOfMinyust"
                          value={application.licenseOfMinyust}
                          className="mt-2"
                          buttonStyle="solid"
                        >
                          {options.map((val) => {
                            return (
                              <Radio.Button key={val.label} value={val.value}>
                                {val.label}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="col">
                      <div className="fv-row d-flex flex-column mb-1">
                        <label className="fs-6 fw-semibold form-label mt-3">
                          <span className="required">
                            {t("licenseOfmintrans")}
                          </span>
                        </label>
                        <Radio.Group
                          onChange={onChangeRadio}
                          name="licenseOfmintrans"
                          value={application.licenseOfmintrans}
                          className="mt-2"
                          buttonStyle="solid"
                        >
                          {options.map((val) => {
                            return (
                              <Radio.Button key={val.label} value={val.value}>
                                {val.label}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row row-cols-2">
                        <div className="col">
                          <div className="fv-row d-flex flex-column mb-1">
                            <label className="fs-6 fw-semibold form-label mt-3">
                              <span className="required">
                                {t("isEconomicSud")}
                              </span>
                            </label>
                            <Radio.Group
                              onChange={onChangeRadio}
                              name="isEconomicSud"
                              value={application?.isEconomicSud}
                              className="mt-2"
                              buttonStyle="solid"
                            >
                              {options.map((val) => {
                                return (
                                  <Radio.Button
                                    key={val.label}
                                    value={val.value}
                                  >
                                    {val.label}
                                  </Radio.Button>
                                );
                              })}
                            </Radio.Group>
                          </div>
                        </div>
                        <div className="col">
                          <div className="fv-row d-flex flex-column mb-1">
                            <label className="fs-6 fw-semibold form-label mt-3">
                              <span className="required">
                                {t("reorganization")}
                              </span>
                            </label>
                            <Radio.Group
                              onChange={onChangeRadio}
                              name="reorganization"
                              value={application.reorganization}
                              className="mt-2"
                              buttonStyle="solid"
                            >
                              {options.map((val) => {
                                return (
                                  <Radio.Button
                                    key={val.label}
                                    value={val.value}
                                  >
                                    {val.label}
                                  </Radio.Button>
                                );
                              })}
                            </Radio.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row row-cols-2">
                        <div className="col">
                          <div className="fv-row d-flex flex-column mb-1">
                            <label className="fs-6 fw-semibold form-label mt-3">
                              <span>
                                {t("catRating")} / {t("ball")}
                              </span>
                            </label>
                            <div className="col">
                              <div className="row row-cols-2">
                                <div className="col">
                                  <Input
                                    type="text"
                                    name="ratingCategory"
                                    onChange={changeHandler}
                                    className="form-control-solid h-40px"
                                    value={application.ratingCategory}
                                  />
                                </div>
                                <div className="col">
                                  <Input
                                    type="text"
                                    name="ratingBall"
                                    onChange={changeHandler}
                                    className="form-control-solid h-40px"
                                    value={application.ratingBall}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <Radio.Group
                              onChange={onChangeRadio}
                              name="pledgeRegister"
                              value={application.pledgeRegister}
                              className="mt-2"
                              buttonStyle="solid"
                            >
                              {options.map((val) => {
                                return (
                                  <Radio.Button
                                    key={val.label}
                                    value={val.value}
                                  >
                                    {val.label}
                                  </Radio.Button>
                                );
                              })}
                            </Radio.Group> */}
                          </div>
                        </div>
                        <div className="col">
                          <div className="fv-row d-flex flex-column mb-1">
                            <label className="fs-6 fw-semibold form-label mt-3">
                              <span className="required">
                                {t("liquidation")}
                              </span>
                            </label>
                            <Radio.Group
                              onChange={onChangeRadio}
                              name="liquidation"
                              value={application.liquidation}
                              className="mt-2"
                              buttonStyle="solid"
                            >
                              {options.map((val) => {
                                return (
                                  <Radio.Button
                                    key={val.label}
                                    value={val.value}
                                  >
                                    {val.label}
                                  </Radio.Button>
                                );
                              })}
                            </Radio.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <label className="fs-6 fw-semibold form-label mt-3">
                        <span className="required">{t("lastCheckGNK")}</span>
                      </label>
                      <Select
                        placeholder={t("choose")}
                        className="w-100 form-control form-select-solid p-0"
                        allowClear
                        value={application.lastCheckGNK}
                        showSearch
                        mode="multiple"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(e) => changeSelectHandler(e, "lastCheckGNK")}
                        options={taxTypes?.map((el) => {
                          return {
                            value: el.value,
                            label: el.value,
                          };
                        })}
                      />
                    </div>
                  </div>
                  <div className="row row-cols-1 row-cols-md-2">
                    <div className="col">
                      <div className="d-flex mt-2 align-items-end flex-wrap">
                        <div className="fv-row d-flex flex-column align-items-start">
                          <label className="fs-6 fw-semibold form-label mt-3">
                            <span className="required">
                              {t("file")} ({t("directorMibDebt")})
                            </span>
                          </label>
                          <label
                            htmlFor="uploadMibFile"
                            className="btn btn-secondary w-200px fs-6 fw-semibold form-label mt-3 mb-4 d-flex flex-column"
                          >
                            {t("chooseFile")}
                          </label>
                          {uploadMib ? (
                            <Spin className="d-flex ms-2" size="small" />
                          ) : (
                            ""
                          )}
                          <input
                            type="file"
                            name="file"
                            ref={mibRef}
                            className="d-none"
                            id="uploadMibFile"
                            onChange={(e) =>
                              changeHandler(e, "file", undefined, "mib")
                            }
                          />
                        </div>
                      </div>
                      <div className="w-100 row row-cols-1 row-cols-sm-2 row-cols-lg-3 mx-0">
                        {mibFiles &&
                          mibFiles.map((file: any, index: number) => {
                            return (
                              <div className="col py-3 ps-0 mb-3" key={index}>
                                <div className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">
                                  <a
                                    href="#/"
                                    onClick={() =>
                                      downloadFile(file?.url, file?.name)
                                    }
                                    className="text-truncate"
                                  >
                                    {file?.name}
                                  </a>
                                  <button
                                    className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2"
                                    onClick={() => deleteFile(file?.id, "mib")}
                                  >
                                    <SVG
                                      icon={<DeleteIcon />}
                                      className="svg-icon-2"
                                    />
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="col">
                      <div className="d-flex mt-2 align-items-end flex-wrap">
                        <div className="fv-row d-flex flex-column align-items-start">
                          <label className="fs-6 fw-semibold form-label mt-3">
                            <span className="required">
                              {t("file")} ({t("licenseOfMinyust")})
                            </span>
                          </label>
                          <label
                            htmlFor="uploadJustFile"
                            className="btn btn-secondary w-200px fs-6 fw-semibold form-label mt-3 mb-4 d-flex flex-column"
                          >
                            {t("chooseFile")}
                          </label>
                          {uploadJust ? (
                            <Spin className="d-flex ms-2" size="small" />
                          ) : (
                            ""
                          )}
                          <input
                            type="file"
                            name="file"
                            ref={justRef}
                            className="d-none"
                            id="uploadJustFile"
                            onChange={(e) =>
                              changeHandler(e, "file", undefined, "just")
                            }
                          />
                        </div>
                      </div>
                      <div className="w-100 row row-cols-1 row-cols-sm-2 row-cols-lg-3 mx-0">
                        {minjustFiles &&
                          minjustFiles.map((file: any, index: number) => {
                            return (
                              <div className="col py-3 ps-0 mb-3" key={index}>
                                <div className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">
                                  <a
                                    href="#/"
                                    onClick={() =>
                                      downloadFile(file?.url, file?.name)
                                    }
                                    className="text-truncate"
                                  >
                                    {file?.name}
                                  </a>
                                  <button
                                    className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2"
                                    onClick={() => deleteFile(file?.id, "just")}
                                  >
                                    <SVG
                                      icon={<DeleteIcon />}
                                      className="svg-icon-2"
                                    />
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="col">
                      <div className="d-flex mt-2 align-items-end flex-wrap">
                        <div className="fv-row d-flex flex-column align-items-start">
                          <label className="fs-6 fw-semibold form-label mt-3">
                            <span className="required">
                              {t("file")} ({t("licenseOfmintrans")})
                            </span>
                          </label>
                          <label
                            htmlFor="uploadTransFile"
                            className="btn btn-secondary w-200px fs-6 fw-semibold form-label mt-3 mb-4 d-flex flex-column"
                          >
                            {t("chooseFile")}
                          </label>
                          {uploadTrans ? (
                            <Spin className="d-flex ms-2" size="small" />
                          ) : (
                            ""
                          )}
                          <input
                            type="file"
                            name="file"
                            ref={transRef}
                            className="d-none"
                            id="uploadTransFile"
                            onChange={(e) =>
                              changeHandler(e, "file", undefined, "trans")
                            }
                          />
                        </div>
                      </div>
                      <div className="w-100 row row-cols-1 row-cols-sm-2 row-cols-lg-3 mx-0">
                        {mintransFiles &&
                          mintransFiles.map((file: any, index: number) => {
                            return (
                              <div className="col py-3 ps-0 mb-3" key={index}>
                                <div className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">
                                  <a
                                    href="#/"
                                    onClick={() =>
                                      downloadFile(file?.url, file?.name)
                                    }
                                    className="text-truncate"
                                  >
                                    {file?.name}
                                  </a>
                                  <button
                                    className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2"
                                    onClick={() =>
                                      deleteFile(file?.id, "trans")
                                    }
                                  >
                                    <SVG
                                      icon={<DeleteIcon />}
                                      className="svg-icon-2"
                                    />
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </>
              ),
            },
          ]}
        ></Tabs>
      </Modal>
    </>
  );
};

export default CreateUpdateSupplier;
