import {PermissionDTO} from "../../services/openapi";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createPermission, getAllPermissions, getOnePermission, updatePermission} from "../services/permissionService";

interface PermissionsState {
    permissions: PermissionDTO[];
    permission: PermissionDTO;
    loading: boolean;
    status: string;
    error: string;
    response: any;
    totalCount: any
}

const initialState: PermissionsState = {
    permissions: [],
    permission: {},
    loading: false,
    status: "",
    error: "",
    response: {},
    totalCount: 0
};

export const permissionSlice = createSlice({
    name: "permission",
    initialState,
    reducers: {
        setPermission: (
            state,
            action: PayloadAction<{ name: any; value: string }>
        ) => {
            state.permission = {
                ...state.permission,
                [action.payload.name]: action.payload.value,
            };
        },
        clearPermission: (state) => {
            state.permission = {
                code: "",
            };
        },
    },
    extraReducers: {
        [getAllPermissions.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [getAllPermissions.fulfilled.type]: (state, {payload}) => {
            state.status = "success";
            state.permissions = payload.content;
            state.totalCount = payload.totalElements;
            state.loading = false;
        },
        [getAllPermissions.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [createPermission.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [createPermission.fulfilled.type]: (state, {payload}) => {
            state.status = "success";
            state.response = payload;
            state.loading = false;
        },
        [createPermission.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [getOnePermission.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [getOnePermission.fulfilled.type]: (state, {payload}) => {
            state.status = "success";
            state.permission = payload;
            state.loading = false;
        },
        [getOnePermission.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [updatePermission.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [updatePermission.fulfilled.type]: (state, {payload}) => {
            state.status = "success";
            state.response = payload;
            state.loading = false;
        },
        [updatePermission.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
    },
});

export const {setPermission, clearPermission} = permissionSlice.actions;

export default permissionSlice.reducer;
