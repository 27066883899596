import { Spin } from "antd";
import Table from "antd/lib/table";
type Props<T> = {
  columns: any;
  loading?: boolean;
  error?: string;
  data: Array<T> | any;
  page?: number;
  paginationChange?: any;
  paginationShowChange?: any;
  pageSize?: number;
  totalCount?: number;
  scrollX?: number;
  scrollY?: number;
  className?: string;
};

const CustomTable = <T extends unknown>(props: Props<T>) => {
  return (
    <div className="table-responsive">
      <Table
        columns={props.columns}
        locale={{ emptyText: props.error ? props.error : "No data" }}
        dataSource={props.data}
        className={`table table-row-bordered table-row-gray-100 align-middle gs-0 gy-1  ${props.className}`}
        loading={{
          indicator: (
            <div>
              <Spin />
            </div>
          ),
          spinning: props.loading,
        }}
        pagination={{
          pageSize: props.pageSize,
          current: props.page! + 1,
          pageSizeOptions: [25, 50, 100],
          defaultPageSize: props.pageSize,
          responsive: true,
          total: props.totalCount!,
          onChange: props.paginationChange,
          onShowSizeChange: props.paginationShowChange,
        }}
        scroll={{ x: props.scrollX || undefined, y: props.scrollY }}
        rowKey={(row) => row?.id || row?.nested?.id || row?.mac || row.key || 'id'}
      />
    </div>
  );
};

export default CustomTable;
